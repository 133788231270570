import React from "react";
import Loader from "../Loader";

interface MainContentProps{
    children: React.ReactNode
    loading: boolean
}

export default function MainContent(props: MainContentProps){
    return(props.loading) ? (
        props.children
    ) : (
        <Loader loading={!props.loading}></Loader>
    )
}