import {SetPop} from "../components/feedback/AlertPop";
    export function DeleteAllLogs() {
        try {
            fetch('https://rovercon.herokuapp.com/delete_log',{
                method: 'delete',
                mode: 'cors'
            }).then((res)=>{
                return res.json()
            }).then((res)=>{
                SetPop(true, "Logs deleted successfully!",'success')
            })
        }catch (e){
            console.log(e, "Cannot delete log")
        }
    }

    export async function GetLogs():Promise<any>{
        try {
            const req = await fetch('https://rovercon.herokuapp.com/get_logs?limit=50')
            return req.json()
        }catch (e){
            console.log(e, "Cannot fetch")
        }
    }