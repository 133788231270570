import Backdrop from '@mui/material/Backdrop';
import Meda1 from '../../assets/rover_system_informator/Meda1.png'
import Meda2 from '../../assets/rover_system_informator/Meda2.png'
import Meda3 from '../../assets/rover_system_informator/Meda3.png'
import ERD from '../../assets/rover_system_informator/ERD.png'
import AQMS from '../../assets/rover_system_informator/AQMS.png'
import Rover from '../../assets/rover_system_informator/Rover.png'

import React from "react";

interface Props{
    is_window_open: boolean,
    state: () => void
}

export function InfoMES1({is_window_open,state}: Props){
    return(
            <Backdrop
                sx={{ color: '#5a5555', zIndex: 2 }}
                open={is_window_open}
                onClick={state}
            >
                <div className={'w-96 h-fit md:w-[500px] bg-color_bckg/80 backdrop-blur-md rounded-2xl flex flex-col justify-start items-center p-2'}>
                    <img src={Meda1} alt="Meda drawing"/>
                    <div className={'w-full h-fit flex flex-col justify-center items-center text-center'}>
                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mb-2'}>MES1</p>
                        <p className={'text-md text-white font-serif'}>MES1 (Meda Environmental Sensor) - First standalone system of MEDA station.</p>
                        <p className={'text-md text-white font-serif'}>MES1 is build with DHT-11 sensor. Measuring temperature and humidity of outside environment.</p>
                    </div>
                </div>
            </Backdrop>
    )
}

export function InfoMES2({is_window_open, state}: Props){
    return(
        <Backdrop
            sx={{ color: '#5a5555', zIndex: 2 }}
            open={is_window_open}
            onClick={state}
        >
            <div className={'w-96 h-fit bg-color_bckg/80 backdrop-blur-md rounded-2xl flex flex-col justify-start items-center p-2'}>
                <img src={Meda2} alt="Meda drawing"/>
                <div className={'w-full h-fit flex flex-col justify-center items-center text-center'}>
                    <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mb-2'}>MES2</p>
                    <p className={'text-md text-white font-serif'}>MES2 (Meda Environmental Sensor) - Second standalone system of MEDA station.</p>
                    <p className={'text-md text-white font-serif'}>MES2 is build with BMP180 sensor. Measuring temperature, pressure and height about mean sea level of outside environment.</p>
                </div>
            </div>
        </Backdrop>
    )
}

export function InfoMES3({is_window_open, state}: Props){
    return(
        <Backdrop
            sx={{ color: '#5a5555', zIndex: 2 }}
            open={is_window_open}
            onClick={state}
        >
            <div
                className={'w-96 h-fit bg-color_bckg/80 backdrop-blur-md rounded-2xl flex flex-col justify-start items-center p-2'}>
                <img src={Meda3} alt="Meda drawing"/>
                <div className={'w-full h-fit flex flex-col justify-center items-center text-center'}>
                    <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mb-2'}>MES3</p>
                    <p className={'text-md text-white font-serif'}>MES3 (Meda Environmental Sensor) - Third standalone
                        system of MEDA station.</p>
                    <p className={'text-md text-white font-serif'}>MES3 is build with DS18B20 sensor. Measuring only
                        temperature of outside environment.</p>
                </div>
                <img src={ERD} alt="ERD drawing"/>
                <div className={'w-full h-fit flex flex-col justify-center items-center text-center'}>
                    <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mb-2'}>E.R.D</p>
                    <p className={'text-md text-white font-serif'}>ERD(Environmental Radiation Detector) - Fourth standalone system of MEDA station.</p>
                    <p className={'text-md text-white font-serif'}>ERD is build with Geiger-Muller J-305 tube. In addiction to low accuracy of the tube. Results of background radiation level are relative only.</p>
                </div>/
            </div>
        </Backdrop>
    )
}

export function InfoMES4({is_window_open, state}: Props){
    return(
        <Backdrop
            sx={{ color: '#5a5555', zIndex: 2 }}
            open={is_window_open}
            onClick={state}
        >
            <div
                className={'w-96 h-fit bg-color_bckg/80 backdrop-blur-md rounded-2xl flex flex-col justify-start items-center p-2'}>
                <img src={AQMS} alt="Meda drawing"/>
                <div className={'w-full h-fit flex flex-col justify-center items-center text-center'}>
                    <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mb-2'}>E.R.D</p>
                    <p className={'text-md text-white font-serif'}>A.Q.M.S (Air Quality Measuring System) - Fifth standalone ststem of MEDA station.</p>
                    <p className={'text-md text-white font-serif'}>AQMS is build with SGP30 sensor. Measuring level of TVOC and CO2 in air.</p>
                </div>
            </div>
        </Backdrop>
    )
}

export function InfoSystem({is_window_open, state}: Props){
    return(
        <Backdrop
            sx={{ color: '#5a5555', zIndex: 2 }}
            open={is_window_open}
            onClick={state}
        >
            <div
                className={'w-96 h-fit bg-color_bckg/80 backdrop-blur-md rounded-2xl flex flex-col justify-start items-center p-2'}>
                <img src={Rover} alt="Meda drawing"/>
                <div className={'w-full h-fit flex flex-col justify-center items-center text-center'}>
                    <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mb-2'}>System</p>
                    <p className={'text-md text-white font-serif'}>S1 - Place where MRB battery is mounted with two
                        sensors. S1 measure MRB cells temperature.</p>
                    <p className={'text-md text-white font-serif'}>S2 - Place where A4988 drivers are mounted with NTC
                        sensor. S2 measure temperature of A4988 drivers.</p>
                    <p className={'text-md text-white font-serif'}>S3 - Place where L298N driver is mounted with NTC
                        sensor. S3 measure temperature of L298N driver.</p>
                </div>
            </div>
        </Backdrop>
    )
}
