
export function SetControlCommand(command: string, arg: string | number, status: string, mcu: string){
    fetch('https://rovercon.herokuapp.com/sys_control',{
        mode: 'cors',
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token: 'FnkLDQkM?p7O62uqULlJxZP70l5IwZZC5IEV/RFY=A25nUqjaoDmrApr4NWGFMXi',
            command: command,
            arg: arg,
            status: status,
            mcu: mcu
        })
    }).then((res)=>{
        return res.json()
    }).then((res)=>{
        console.log(res)
    }).catch((err)=>{
        console.error("Error: ",err)
        throw err
    })
}