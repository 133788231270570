
export function S1TempWatcher(t: number): number{
    if (!t){
        return 0
    }
     // 85 -> max safe temp for 18650 driver
    return 80 - t
}

export function S2TempWatcher(t: number): number{
    if (!t){
        return 0
    }
     // 85 -> max safe temp for A4988 driver
    return 85 - t
}

export function S3TempWatcher(t: number): number{
    if (!t){
        return 0
    }
     // 85 -> max safe value for L298N driver
    return 130 - t
}

export function FanWatcher(s: number): string{
    if(s === 0){
        return 'red'
    }

    return 'green'
}

export function SatWatcher(n: number): string{
    if(n === 0){
        return 'red'
    }

    return 'green'
}

export function GpsWatcher(cord: number): string{
    if(cord == 0){
        return 'red'
    }

    return 'green'
}

