import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/Sidebar";
import MainContent from "../components/dashboard/MainContent";
import React, {useState, useEffect, useRef} from "react";
import {GetDataMcuAtu,GetDataMcuCaro} from "../services/getDatamcu";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material/styles";
import {LineChart} from "@mui/x-charts/LineChart";
import {GetLogs,DeleteAllLogs} from "../services/Logs";
import {Button} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Chip from "@mui/material/Chip";
import {ESPIconOne} from "../components/icons/ESPIconOne";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SerialMonitor from "../services/Serial";


interface IPLot{
    rssi_caro: number[],
    rssi_atu: number[],
    time_stamp_caro: string[],
    time_stamp_atu: string[]
}

export default function System(){
    const [loading,Set_loading] = useState<boolean>(false)
    const [DataCaro, SetDataCaro] = useState<any>()
    const [DataAtu, SetDataAtu] = useState<any>()
    const [Logs,SetLogs] = useState<any>({})
    const [ServerDate,SetServerDate] = useState<string[]>([])
    const [PlotData, SetPlotData] = useState<IPLot>({
        rssi_caro: [0],
        rssi_atu: [0],
        time_stamp_caro: ['0'],
        time_stamp_atu: ['0']
    })
    const listRef = useRef<any>(null)
    const [AutoScroll, SetAutoScroll] = useState<boolean>(false)
    const [DialogOpen, SetDialogOpen] = useState<boolean>(false)

    const theme = createTheme({palette: {mode: "dark"}})

    const FetchData = async (): Promise<void> =>{
        const atu = await GetDataMcuAtu(10)
        const caro = await GetDataMcuCaro(10)
        const logs = await GetLogs()

        let date_hour: string[] = []
        let date_day: string[] = []

        let time = atu.map((el: any) => el.data.time_stamp)
        time.forEach((el: any) =>{
            date_hour.push(el.split(/(\s+)/)[2])
            date_day.push(el.split(/(\s+)/)[0])
        })

        SetPlotData({
            rssi_caro: caro.map((el: any) => el.data.rssi),
            rssi_atu: atu.map((el: any) => el.data.rssi),
            time_stamp_atu: atu.map((el: any) => el.data.time_stamp),
            time_stamp_caro: caro.map((el: any) => el.data.time_stamp),
        })

        SetDataAtu(atu[0 as keyof unknown].data)
        SetDataCaro(caro[0 as keyof unknown].data)
        SetLogs(logs)
        SetServerDate(date_day)
    }

    function HandleLogDelete(): void{
        SetDialogOpen(false)
        DeleteAllLogs()
    }

    function AvaibleLogs(): boolean{
        if(Object.keys(Logs).length !== 0){
            return false
        }else {
            return false
        }
    }

    const ShowLogs = ()=>{
        // if(AutoScroll){
        //     listRef.current?.lastElementChild?.scrollIntoView()
        // }

        return(loading) ? (
            <ul ref={listRef}>
                {Logs.map((log: any)=>(
                    <li className={'font-serif'} key={log.id}>{`[${log.time_stamp}] => ${log.log}`}</li>
                ))}
            </ul>
        ) : <></>
    }

    const DownloadLogs = (): void=>{
        const utc: string = new Date().toJSON().slice(0, 10).replace(/-/g, '/');

        const blob: Blob = new Blob([JSON.stringify(Logs)],{type: 'text/plain'})
        const url: string = URL.createObjectURL(blob)
        const link: HTMLAnchorElement = document.createElement("a");
        link.download = `${utc}.txt`;
        link.href = url;
        link.click();
    }

    const DialogWindow = ()=>{
        return(
            <Dialog
                open={DialogOpen}
                onClose={()=>{SetDialogOpen(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Do you want to delete all system logs?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       All system logs will be permanently deleted!
                        Do you agree with that?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'error'} onClick={()=>{HandleLogDelete()}}>YES</Button>
                    <Button color={'success'} onClick={()=>SetDialogOpen(false)} autoFocus>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    useEffect(()=>{
        FetchData().then(()=>{
            Set_loading(true)
        })

        let interval = setInterval(()=>
            FetchData().then(()=>{
                Set_loading(true)
            }),1500)

        return ()=>{
            clearInterval(interval)
        }
    },[])


    const render = ()=>{

        return(loading) ? (
            <div className={'h-[calc(100vh-64px)] w-[calc(100vw-64px)] lg:w-[calc(100vw-160px)] flex flex-col justify-start items-start overflow-auto'}>
                <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <p className={'text-white font-serif'}>MCU CARO INFO</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'w-full h-fit flex flex-wrap justify-center xl:justify-start gap-4'}>
                            <div
                                className="w-60 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center">
                                <p className={'text-md text-white font-mono border-b-2 border-amber-300 fixed top-0'}>NETWORK</p>
                                <div className={'flex flex-col justify-center items-center gap-2'}>
                                    <Chip sx={{bgcolor: '#ffde75'}} icon={<ESPIconOne/>} variant={'filled'}
                                          label={`RSSI ${DataCaro.rssi} dBm`} color={'primary'}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`IP ${DataCaro.LocalIp} `}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'} label={`SSID 1283-NET`}></Chip>
                                </div>
                            </div>
                            <div
                                className="w-60 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center">
                                <p className={'text-md text-white font-mono border-b-2 border-amber-300 fixed top-0'}>TECHNICAL
                                    DATA</p>
                                <div className={'flex flex-col justify-center items-center gap-2'}>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`ESP_IDF V ${DataCaro.ESP_IDF_version}`}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`FCS ${DataCaro.Flash_chip_speed}`}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`MODEL ${DataCaro.Model}`}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`PSRAM ${DataCaro.PSRAM}`}></Chip>
                                </div>
                            </div>
                            <div
                                className={'w-4/12 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                <LineChart
                                    xAxis={[{
                                        scaleType: 'point',
                                        data: PlotData.time_stamp_caro,
                                        label: `Date - ${ServerDate[0]}`
                                    }]}
                                    series={[
                                        {
                                            data: PlotData.rssi_caro,
                                            label: "MCU CARO RSSI",
                                            color: "#ffde75",
                                            showMark: false
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <p className={'text-white font-serif'}>MCU ATU INFO</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'w-full h-fit flex flex-wrap justify-center xl:justify-start gap-4'}>
                            <div
                                className="w-60 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center">
                                <p className={'text-md text-white font-mono border-b-2 border-amber-300 fixed top-0'}>NETWORK</p>
                                <div className={'flex flex-col justify-center items-center gap-2'}>
                                    <Chip sx={{bgcolor: '#ffde75'}} icon={<ESPIconOne/>} variant={'filled'}
                                          label={`RSSI ${DataAtu.rssi} dBm`} color={'primary'}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`IP ${DataAtu.LocalIp} `}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'} label={`SSID 1283-NET`}></Chip>
                                </div>
                            </div>
                            <div
                                className="w-60 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center">
                                <p className={'text-md text-white font-mono border-b-2 border-amber-300 fixed top-0'}>TECHNICAL
                                    DATA</p>
                                <div className={'flex flex-col justify-center items-center gap-2'}>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`ESP_IDF V ${DataAtu.ESP_IDF_version}`}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`FCS ${DataAtu.Flash_chip_speed}`}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`MODEL ${DataAtu.Model}`}></Chip>
                                    <Chip icon={<ESPIconOne/>} variant={'filled'}
                                          label={`PSRAM ${DataAtu.PSRAM}`}></Chip>
                                </div>
                            </div>
                            <div
                                className={'w-4/12 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                <LineChart
                                    xAxis={[{
                                        scaleType: 'point',
                                        data: PlotData.time_stamp_atu,
                                        label: `Date - ${ServerDate[0]}`
                                    }]}
                                    series={[
                                        {
                                            data: PlotData.rssi_atu,
                                            label: "MCU CARO RSSI",
                                            color: "#ffde75",
                                            showMark: false
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <p className={'text-white font-serif'}>LOG CONSOLE</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'w-full h-fit flex flex-col'}>
                            <div className={'w-full h-fit flex bg-black rounded-t-2xl'}>
                                <IconButton  color={'error'} disabled={AvaibleLogs()} onClick={()=>SetDialogOpen(true)}>
                                    <DeleteIcon></DeleteIcon>
                                </IconButton>
                                <IconButton color={'info'} disabled={AvaibleLogs()} onClick={()=>DownloadLogs()}>
                                    <DownloadIcon></DownloadIcon>
                                </IconButton>
                            </div>
                            <div className="w-full h-96 bg-black overflow-auto flex flex-col-reverse">
                                {ShowLogs()}
                            </div>
                            {DialogWindow()}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <p className={'text-white font-serif'}>SERIAL MONITOR</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SerialMonitor></SerialMonitor>
                    </AccordionDetails>
                </Accordion>
            </div>
        ) : <></>
    }

    return (
        <div className="static w-screen h-screen flex flex-col">
            <Header></Header>
            <div className={'flex flex-row'}>
                <Sidebar></Sidebar>
                <MainContent loading={loading}>
                    {render()}
                </MainContent>
            </div>
        </div>
    )
}