import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import sync_icon from '../assets/dashboard_icons/icons8-sync-48.png'
import design_icon from '../assets/dashboard_icons/icons8-design-48.png'
import microchip_icon from '../assets/dashboard_icons/icons8-electronics-48.png'
import pcb_icon from '../assets/dashboard_icons/icons8-circuit-board-48.png'
import rover_pres1 from '../assets/rover_presentation/rov_pres1.webp'
import rover_pres2 from '../assets/rover_presentation/rov_pres2.webp'
import rover_pres3 from '../assets/rover_presentation/rov_pres3.webp'
import rover_pres4 from '../assets/rover_presentation/API.webp'
import {GetDataMcuAtu} from "../services/getDatamcu";
import {LineChart} from "@mui/x-charts/LineChart";
import {Menu, MenuItem, createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import GitHubIcon from '@mui/icons-material/GitHub';
import Typewriter from 'typewriter-effect'
import {IconButton} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import roverconIcon from '../assets/rovercon.svg'

const theme  = createTheme({
    palette: {
        primary: {
            main: '#FFC107'
        },
        secondary: {
            main: '#ff3a00'
        },
        mode: 'dark'
    }
})

interface IPlot{
    bmp_temp: number[],
    bmp_press: number[],
    dht_temp: number[],
    dht_hum: number[],
    time_stamp: string[]
}

export default function Home(){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [loading,Set_loading] = useState<boolean>(false)
    const [PlotData, SetPLotData] = useState<IPlot>({
        bmp_temp: [0],
        bmp_press: [0],
        dht_temp: [0],
        dht_hum: [0],
        time_stamp: ['0']
    })
    const [ServerDate,SetServerDate] = useState<string[]>([])

    const FetchData = async ():Promise<void> =>{
        const atu = await GetDataMcuAtu(10)

        let date_hour: string[] = []
        let date_day: string[] = []

        let time = atu.map((el: any) => el.data.time_stamp)
        time.forEach((el: any) =>{
            date_hour.push(el.split(/(\s+)/)[2])
            date_day.push(el.split(/(\s+)/)[0])
        })

        SetPLotData({
            dht_temp: atu.map((el: any) => el.data.DHT_temp),
            dht_hum: atu.map((el: any) => el.data.DHT_hum),
            bmp_temp: atu.map((el: any) => el.data.BMP_temp),
            bmp_press: atu.map((el: any) => el.data.BMP_press),
            time_stamp: atu.map((el: any) => el.data.time_stamp),
        })
        SetServerDate(date_day)
    }

    useEffect(() => {
        FetchData().then(()=>{
            Set_loading(true)
        }).catch((err)=>{
            console.error("Error: ", err)
            throw err
        })
    }, []);

    const render = ()=>{
        return(loading) ?
            (
                <ThemeProvider theme={theme}>
                    <div className={'w-full h-fit flex flex-wrap justify-center items-center gap-2 mt-5 mb-10'}>
                        <LineChart
                            xAxis={[{data: PlotData.time_stamp, label: "Number of measurements",scaleType: 'point'}]}
                            series={[
                                {
                                    data: PlotData.dht_temp,
                                    label: "Temperature DHT [°C]",
                                    color: "#1aff00",
                                    showMark: false,
                                    curve: 'natural'
                                },
                            ]}
                            width={400}
                            height={300}
                        />
                        <LineChart
                            xAxis={[{data: PlotData.time_stamp, label: "Number of measurements",scaleType: 'point'}]}
                            series={[
                                {
                                    data: PlotData.dht_hum,
                                    label: "Humidity DHT [°C]",
                                    color: "#1aff00",
                                    showMark: false,
                                    curve: 'natural'
                                },
                            ]}
                            width={400}
                            height={300}
                        />
                        <LineChart
                            xAxis={[{data: PlotData.time_stamp, label: "Number of measurements",scaleType: 'point'}]}
                            series={[
                                {
                                    data: PlotData.bmp_temp,
                                    label: "Temperature BMP [°C]",
                                    color: "#1aff00",
                                    showMark: false,
                                    curve: 'natural'
                                },
                            ]}
                            width={400}
                            height={300}
                        />
                        <LineChart
                            xAxis={[{data: PlotData.time_stamp, label: "Number of measurements",scaleType: 'point'}]}
                            series={[
                                {
                                    data: PlotData.bmp_press,
                                    label: "Pressure BMP [°C]",
                                    color: "#1aff00",
                                    showMark: false,
                                    curve: 'natural'
                                },
                            ]}
                            width={400}
                            height={300}
                        />
                    </div>
                </ThemeProvider>
            ) : <></>
    }

    return (
        <div className="w-full h-screen bg-bcgk bg-cover bg-center">
            <div className={'fixed w-full h-fit z-20 flex justify-center items-center'}>
                <div className={'w-10/12 lg:w-6/12 h-fit bg-white/10 backdrop-blur-xl p-3 m-3 rounded-3xl flex'}>
                    <div>
                        <img src={roverconIcon} alt="rovercon icon" className={'w-10'}/>
                    </div>
                    <div className={'ml-auto gap-2 hidden lg:flex'}>
                    <Button style={{borderRadius: 16}} variant={'contained'} href={'https://github.com/arcziosppl'} target={'_blank'} color={'warning'} startIcon={<GitHubIcon />}>ABOUT</Button>
                    <Button style={{borderRadius: 16}} variant={'contained'} href={'/login'} color={'warning'} startIcon={<LoginIcon />}>Sign in</Button>
                    </div>
                    <div className={'ml-auto gap-2 flex lg:hidden'}>
                        <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MenuIcon></MenuIcon>
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleClose}><Button style={{borderRadius: 16}} variant={'contained'} href={'https://github.com/arcziosppl'} target={'_blank'} color={'warning'} startIcon={<GitHubIcon />}>ABOUT</Button></MenuItem>
                            <MenuItem onClick={handleClose}><Button style={{borderRadius: 16}} variant={'contained'} href={'/login'} color={'warning'} startIcon={<LoginIcon />}>Sign in</Button></MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
            <div className="w-full h-full flex flex-col justify-center items-center backdrop-blur-sm">
                <h1 className="mt-5 text-center text-4xl text-white font-semibold drop-shadow-lg">WELCOME TO <span
                    className="text-yellow-300">ROVERCON SYSTEM</span>
                </h1>
            </div>
            <div className={'w-full h-fit  bg-color_bckg_all_black flex flex-col items-center'}>
                <div className={'w-full h-fit flex flex-col justify-center items-center mt-6'}>
                    <p className={'text-4xl text-white font-semibold'}>Key Features</p>
                    <p className={'text-md text-white font-mono mt-1'}>Design to <span
                        className={'underline'}>function</span>, not just look good!</p>
                </div>
                <div className={'w-full h-fit flex flex-wrap gap-2 justify-center mt-20'}>
                    <div
                        className={'container w-3/12 min-w-[350px] max-w-[400px] h-fit border-white border-2 rounded-2xl flex justify-center items-center p-2'}>
                        <img src={sync_icon} alt="sync iocn"/>
                        <div className={'w-full h-full flex flex-col justify-center items-center'}>
                            <p className={'text-xl font-semibold text-white'}>Real-time synchronization</p>
                            <p className={'text-sm font-mono text-white pl-2'}>Real-time synchronization for all
                                collected data</p>
                        </div>
                    </div>
                    <div
                        className={'container w-3/12 min-w-[350px] max-w-[400px] h-fit border-white border-2 rounded-2xl flex justify-center items-center p-2'}>
                        <img src={design_icon} alt="sync iocn"/>
                        <div className={'w-full h-full flex flex-col justify-center items-center text-justify'}>
                            <p className={'text-xl font-semibold text-white'}>Modular design</p>
                            <p className={'text-sm font-mono text-white pl-2'}>Modular construction built from
                                individual
                                panels</p>
                        </div>
                    </div>
                    <div
                        className={'container w-3/12 min-w-[350px] max-w-[400px] h-fit border-white border-2 rounded-2xl flex justify-center items-center p-2'}>
                        <img src={pcb_icon} alt="sync iocn"/>
                        <div className={'w-full h-full flex flex-col justify-center items-center text-justify'}>
                            <p className={'text-xl font-semibold text-white'}>Pcb Components</p>
                            <p className={'text-sm font-mono text-white pl-2'}>Each component has its own and
                                independent
                                pcb</p>
                        </div>
                    </div>
                    <div
                        className={'container w-3/12 min-w-[350px] max-w-[400px] h-fit border-white border-2 rounded-2xl flex justify-center items-center p-2'}>
                        <img src={microchip_icon} alt="sync iocn"/>
                        <div className={'w-full h-full flex flex-col justify-center items-center text-justify'}>
                            <p className={'text-xl font-semibold text-white'}>ESP-32 MCU</p>
                            <p className={'text-sm font-mono text-white pl-2'}>ESP-32 microcontrollers controlling every
                                aspect of the robot</p>
                        </div>
                    </div>
                </div>
                <hr className={'bg-white w-9/12 h-0.5 mt-20 border-0'}/>
                <div className={'w-full h-fit mt-20 flex flex-col justify-center items-center'}>
                    <div className={'w-11/12 md:w-9/12 h-fit flex flex-col md:flex-row  justify-center items-center'}>
                        <div className={'h-fit w-9/12 md:w6/12 flex justify-center'}>
                            <p className={'text-md font-mono text-white pl-2'}>Real-time data synchronization plays a
                                crucial role in ensuring efficient and accurate operations. This process involves the
                                continuous and instantaneous exchange of data between a robot and its control systems
                                and sensors.</p>
                        </div>
                        <div className={'h-fit w-9/12 mt-2 md:w-6/12 md:mt-0 flex justify-center'}>
                            <img className={'w-[150%] rounded-3xl'} src={rover_pres4} alt="diagram"/>
                        </div>
                    </div>
                    <hr className={'bg-white w-2/12 h-0.5 mt-10 mb-10 border-0'}/>
                    <div
                        className={'w-11/12 md:w-9/12 h-fit flex flex-col md:flex-row-reverse  justify-center items-center '}>
                        <div className={'h-fit w-9/12 md:w6/12 flex justify-center'}>
                            <p className={'text-md font-mono text-white pl-2'}>Modular design in robotics represents a
                                transformative approach, akin to using building blocks to create complex, adaptable
                                machines. This concept involves the development of standardized modules - each with
                                specific functionalities - that can be easily assembled, disassembled, and reconfigured.
                                The beauty of this lies in its flexibility and efficiency.</p>
                        </div>
                        <div className={'h-fit w-9/12 mt-2 md:w-6/12 md:mt-0 flex justify-center'}>
                            <img className={'w-[150%] rounded-3xl'} src={rover_pres1} alt="diagram"/>
                        </div>
                    </div>
                    <hr className={'bg-white w-2/12 h-0.5 mt-10 mb-10 border-0'}/>
                    <div
                        className={'w-11/12 md:w-9/12 h-fit flex flex-col md:flex-row  justify-center items-center '}>
                        <div className={'h-fit w-9/12 md:w6/12 flex justify-center'}>
                            <p className={'text-md font-mono text-white pl-2'}>Using Printed Circuit Boards (PCBs) in
                                electronic designs has become a cornerstone of modern electronics. PCBs provide a robust
                                and efficient platform for arranging electronic components in a compact and organized
                                manner. Their ability to support both electrical connectivity and physical structure for
                                components like resistors, capacitors, and integrated circuits is crucial.</p>
                        </div>
                        <div className={'h-fit w-9/12 mt-2 md:w-6/12 md:mt-0 flex justify-center'}>
                            <img className={'w-[150%] rounded-3xl'} src={rover_pres3} alt="diagram"/>
                        </div>
                    </div>
                    <hr className={'bg-white w-2/12 h-0.5 mt-10 mb-10 border-0'}/>
                    <div
                        className={'w-11/12 md:w-9/12 h-fit flex flex-col md:flex-row-reverse  justify-center items-center'}>
                        <div className={'h-fiAccordionDetailst w-9/12 md:w6/12 flex justify-center'}>
                            <p className={'text-md font-mono text-white pl-2'}>
                                The ESP32, developed by Espressif Systems, is a versatile and powerful microcontroller
                                that has rapidly gained popularity in the world of Internet of Things (IoT) and DIY
                                electronics. It's an upgrade from its predecessor, the ESP8266, known for its low cost
                                and Wi-Fi capabilities. The ESP32 extends these features by adding Bluetooth
                                connectivity, more GPIOs, analog inputs, and enhanced processing power with its
                                dual-core processor.</p>
                        </div>
                        <div className={'h-fit w-9/12 mt-2 md:w-/12 md:mt-0 flex justify-center'}>
                            <img className={'w-[150%] rounded-3xl'} src={rover_pres2} alt="diagram"/>
                        </div>
                    </div>
                </div>
                <hr className={'bg-white w-9/12 h-0.5 mt-20 border-0'}/>
                <p className={'text-4xl text-white font-semibold mt-20'}>Collected Data</p>
                <p className={'text-sm text-white font-mono mt-1'}>Last 10 data records</p>
                {render()}
            </div>
        </div>
    )
}