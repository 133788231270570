import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home'
import Login from './pages/login'
import Dashboard from "./pages/dashboard";
import Account from "./pages/Account";
import Charts from "./pages/Charts";
import Control from "./pages/Control";
import System from "./pages/System";
import Mission from './pages/Mission'
import './index.scss'
import ProtectedRoute from './components/ProtectedRoute'
export default function App(){

  return (
      <BrowserRouter>
        <Routes>
            <Route path={'/'} element={<Home/>} />
            <Route path={'/login'} element={<Login/>} />
            <Route path={'/dashboard'} element={<ProtectedRoute><Dashboard/></ProtectedRoute>} />
            <Route path={'/dashboard/charts'} element={<ProtectedRoute><Charts/></ProtectedRoute>} />
            <Route path={'/dashboard/system'} element={<ProtectedRoute><System/></ProtectedRoute>} />
            <Route path={'/dashboard/control'} element={<ProtectedRoute><Control/></ProtectedRoute>} />
            <Route path={'/dashboard/account'} element={<ProtectedRoute><Account/></ProtectedRoute>} />
            <Route path={'/dashboard/mission'} element={<ProtectedRoute><Mission /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
  )
}