import React, {useState} from "react";
import {TextField, Button} from "@mui/material";
import failed_icons from '../assets/dashboard_icons/icons8-cancel-48.png'
import {useNavigate} from "react-router-dom";
import Cookies from 'universal-cookie'
import {LoadingButton} from "@mui/lab";
import GitHubIcon from "@mui/icons-material/GitHub";
import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';
import LockIcon from '@mui/icons-material/Lock';



export default  function Login(){

    const [pass,setPass] = useState<string>('')
    const [mail,setMail] = useState<string>('')
    const [trigger,setTrigger] = useState<boolean>(false)
    const [loading, Setloading] = useState<boolean>(false)
    const navigate = useNavigate()
    const cookies = new Cookies()


    const login_failed = ()=>{
        // @ts-ignore
        return trigger ? (
            <div className={'fixed top-10 w-fit h-fit flex flex-col justify-center items-center bg-white/50 rounded-lg p-5 backdrop-blur-md'}>
                <img src={failed_icons} alt='login failed'/>
                <p className={'font-serif'}>Login failed!</p>
                <p className={'font-serif'}>Invalid mail or password!</p>
                <Button onClick={() =>{setTrigger(false)}} color={"error"} variant={"contained"}>OK</Button>
            </div>
        ) : ''
    }

    const login = ()=>{
        Setloading(true)
        try {
            fetch('https://rovercon.herokuapp.com/login', {
                method: 'POST',
                body: new URLSearchParams({
                    mail: mail,
                    pass: pass
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                }
            }).then((res) => {
                return res.json()
            }).then((res) => {
                if (res.success !== true && res.failed !== false) {
                    setTrigger(true)
                    Setloading(false)
                } else {
                    cookies.set('auth-token',res.token,{path: '/dashboard',expires: new Date(Date.now()+2592000)})
                    window.location.href = '/dashboard'
                    console.log(res)
                }
            })
        }catch (e)
        {
            console.log(e)
        }
    }

    return(
        <div className={'flex justify-center items-center h-screen w-screen'}>
            {login_failed()}
           <div className={'w-7/12 h-full bg-rover_bckg_login bg-cover bg-center hidden md:flex'}></div>
            <div className="w-full md:w-5/12 h-full">
                <div className={'w-full h-96 flex flex-col justify-center items-center gap-2'}>
                    <Avatar sx={{ width: 56, height: 56, bgcolor: '#ab47bc' }}>
                        <LockIcon></LockIcon>
                    </Avatar>
                    <p className={'text-3xl text-white font-sans'}>SIGN IN</p>
                    <TextField error={trigger} onChange={(e)=>{setMail(e.target.value)}} required={true} id="mail" label="Email address" variant="standard" sx={{width: '60%'}}/>
                    <TextField error={trigger} onChange={(e)=>{setPass(e.target.value)}} required={true} id="password" label="Password" variant="standard" sx={{width: '60%'}} type='password'/>
                    <LoadingButton color={trigger ? "error" : "primary"} loading={loading} onClick={()=>login()} variant={'contained'} sx={{width: '60%'}}>SIGN IN</LoadingButton>
                </div>
            </div>
        </div>
    )

}