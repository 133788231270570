import SvgIcon from '@mui/material/SvgIcon';
import { createSvgIcon } from '@mui/material/utils';

export default function TVOCIcon() {
    return (
        <SvgIcon>
            <svg className={'w-6 h-6'} viewBox="0 0 141 43" xmlns="http://www.w3.org/2000/svg">
                <g id="fontsvg1713373447804" strokeLinecap="round" fillRule="evenodd" fill="#000000">
                    <path
                        d="M 135.938 42.188 L 117.188 42.188 L 117.188 37.5 L 112.5 37.5 L 112.5 4.688 L 117.188 4.688 L 117.188 0 L 135.938 0 L 135.938 4.688 L 140.625 4.688 L 140.625 14.063 L 131.25 14.063 L 131.25 4.688 L 121.875 4.688 L 121.875 37.5 L 131.25 37.5 L 131.25 28.125 L 140.625 28.125 L 140.625 37.5 L 135.938 37.5 L 135.938 42.188 Z M 56.25 42.188 L 46.875 42.188 L 46.875 37.5 L 42.188 37.5 L 42.188 32.813 L 37.5 32.813 L 37.5 0 L 46.875 0 L 46.875 32.813 L 56.25 32.813 L 56.25 0 L 65.625 0 L 65.625 32.813 L 60.938 32.813 L 60.938 37.5 L 56.25 37.5 L 56.25 42.188 Z M 18.75 42.188 L 9.375 42.188 L 9.375 4.688 L 0 4.688 L 0 0 L 28.125 0 L 28.125 4.688 L 18.75 4.688 L 18.75 42.188 Z M 98.438 42.188 L 79.688 42.188 L 79.688 37.5 L 75 37.5 L 75 4.688 L 79.688 4.688 L 79.688 0 L 98.438 0 L 98.438 4.688 L 103.125 4.688 L 103.125 37.5 L 98.438 37.5 L 98.438 42.188 Z M 84.375 37.5 L 93.75 37.5 L 93.75 4.688 L 84.375 4.688 L 84.375 37.5 Z"
                        vectorEffect="non-scaling-stroke"/>
                </g>
            </svg>
        </SvgIcon>
    )
}