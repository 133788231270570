import React, {useEffect, useState} from 'react'
import Alert from '@mui/material/Alert'
import Fade from '@mui/material/Fade';



export function AlertPop(){

    const [Pop, SetPop] = useState<{pop_state: boolean, pop_type: string, pop_content: string}>({
        pop_state: false,
        pop_type: '',
        pop_content: ''
    })

    useEffect(() => {
        const pop: string = localStorage.getItem('pop')!
       if(pop){
           const alert_data: any = JSON.parse(pop)
           SetPop({
               pop_state: alert_data.pop_state,
               pop_type: alert_data.pop_type,
               pop_content: alert_data.pop_content
           })

           setTimeout(()=>{
               DeletePop()
           },8000)
       }


    }, [localStorage.getItem('pop')]);


    const render = ()=>{

        if(!Pop.pop_state){
            return(
                <></>
            )
        }

        if(Pop.pop_type === 'success'){
            return(
                <Fade in={Pop.pop_state}>
            <Alert severity='success' onClose={()=>{DeletePop()}}>{Pop.pop_content}</Alert>
                </Fade>
            )
        }else if(Pop.pop_type === 'warning'){
            return(
                <Fade in={Pop.pop_state}>
            <Alert severity='warning' onClose={()=>{DeletePop()}}>{Pop.pop_content}</Alert>
                </Fade>
            )
        }else if(Pop.pop_type === 'info'){
            return (
                <Fade in={Pop.pop_state}>
                <Alert severity='info' onClose={()=>{DeletePop()}}>{Pop.pop_content}</Alert>
                </Fade>
            )
        }else {
            return (
                <Fade in={Pop.pop_state}>
                <Alert severity='error' onClose={()=>{DeletePop()}}>{Pop.pop_content}</Alert>
                </Fade>
            )
        }
    }

    return(
        <div className={'fixed z-20'}>
            {render()}
        </div>
    )
}

export function SetPop(isopen: boolean,content: string | undefined,type: string | undefined){
    const pop: {pop_state: boolean, pop_content: string | undefined, pop_type: string | undefined} = {
        pop_state: isopen,
        pop_content: content,
        pop_type: type
    }

    localStorage.setItem('pop', JSON.stringify(pop))
}

function DeletePop(){
    SetPop(false,'','')
}