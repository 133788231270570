
interface IPlot{
    dht_temp: [0],
    dht_hum: [0],
    bmp_alt: [0],
    bmp_press: [0],
    bmp_temp: [0],
    bckg_radiation: [0],
    ds18b20: [0],
    id: [0],
    time_stamp_caro: ['0'],
    time_stamp_atu: ['0'],
    co2: [0],
    voc: [0],
    gps_speed: [0],
    gps_msl: [0],
    gps_online_sat: [0]
}

export async function GetDataMcuAtu(limit: number): Promise<any>{
    const req :Response = await fetch(`https://rovercon.herokuapp.com/get_data_mcu_atu_db_limit?limit=${limit}`)
    return req.json()
}

export async function GetDataMcuCaro(limit: number): Promise<any>{
    const req :Response = await fetch(`https://rovercon.herokuapp.com/get_data_mcu_caro_db_limit?limit=${limit}`)
    return req.json()
}

export async function GetDataMcuAtuPlot(limit: number){
    const data: {dht_temp: number[],dht_hum: number[],bmp_temp: number[],bmp_press: number[],bmp_alt: number[],bckg_radiation: number[],id: number[],ds18b20: number[], time_stamp: string[], co2: number[],voc: number[]} = {
        dht_temp: [0],
        dht_hum: [0],
        bmp_alt: [0],
        bmp_press: [0],
        bmp_temp: [0],
        bckg_radiation: [0],
        ds18b20: [0],
        id: [0],
        time_stamp: ['0'],
        co2: [0],
        voc: [0]
    }

    try {
        const req = await fetch(`https://rovercon.herokuapp.com/get_data_mcu_atu_db_limit?limit=${limit}`)
        const res = await req.json()
        if(res){
            return data
        }

        data.dht_temp = res.map((el: any) => el.data.DHT_temp)
        data.dht_hum = res.map((el: any) => el.data.DHT_hum)
        data.bmp_temp = res.map((el: any) => el.data.BMP_temp)
        data.bmp_alt = res.map((el: any) => el.data.BMP_alt)
        data.bmp_press = res.map((el: any) => el.data.BMP_press)
        data.bckg_radiation = res.map((el: any) => el.data.bckg_radiation)
        data.ds18b20 = res.map((el: any) => el.data.DS18B20_temp)
        data.co2 = res.map((el: any) => el.data.CO2)
        data.voc = res.map((el: any) => el.data.TVOC)
        data.time_stamp = res.map((el: any) => el.data.time_stamp)
        data.id = res.map((el: any) => el.id)
        return data
    }catch (err){
        console.error("Error: ", err)
        throw err
    }
}

export async function GetDataComponentMonitoring(){
    const data:  {a4988_temp: string,l298_temp: string, mrb_temp: string,mrb_voltage: string,sat_count: string,fan1: string,fan2: string} = {
        a4988_temp: "0",
        l298_temp: "0",
        mrb_temp: "0",
        mrb_voltage: "0",
        sat_count: "0",
        fan1: "0",
        fan2: "0"
    }
    const req = await fetch(`https://rovercon.herokuapp.com/get_data_mcu_atu_db_limit?limit=10`)
    const req2 = await fetch('https://rovercon.herokuapp.com/get_data_mcu_caro_db_limit?limit=10')
    const res = await req.json()
    const res2 = await req2.json()

    data.a4988_temp = res[0 as keyof unknown].data.A4988_temp
    data.mrb_temp = res[0 as keyof unknown].data.MRB_temp
    data.l298_temp = res[0 as keyof unknown].data.L298_temp
    data.mrb_voltage = res2[0 as keyof unknown].data.mrb_voltage
    data.sat_count = res2[0 as keyof unknown].data.sat_num
    data.fan1 = res[0 as keyof unknown].data.FAN1
    data.fan2 = res[0 as keyof unknown].data.FAN2

    return data
}

export async function GetChartsData(){
    const data: {dht_temp: number[],dht_hum: number[],bmp_temp: number[],bmp_press: number[],bmp_alt: number[],bckg_radiation: number[],ds18b20: number[], time_stamp_caro: string[],time_stamp_atu: string[], co2: number[],voc: number[], gps_speed: number[], gps_msl: number[], gps_online_sat: number[]} = {
        dht_temp: [0],
        dht_hum: [0],
        bmp_alt: [0],
        bmp_press: [0],
        bmp_temp: [0],
        bckg_radiation: [0],
        ds18b20: [0],
        time_stamp_caro: ['0'],
        time_stamp_atu: ['0'],
        co2: [0],
        voc: [0],
        gps_speed: [0],
        gps_msl: [0],
        gps_online_sat: [0]
    }

    try {
        const req1 = await fetch('https://rovercon.herokuapp.com/get_data_mcu_atu_db_all')
        const req2 = await fetch('https://rovercon.herokuapp.com/get_data_mcu_caro_db_all')
        const res_atu = await req1.json()
        const res_caro = await req2.json()

        data.dht_temp = res_atu.map((el: any) => el.data.DHT_temp)
        data.dht_hum = res_atu.map((el: any) => el.data.DHT_hum)
        data.bmp_temp = res_atu.map((el: any) => el.data.BMP_temp)
        data.bmp_alt = res_atu.map((el: any) => el.data.BMP_alt)
        data.bmp_press = res_atu.map((el: any) => el.data.BMP_press)
        data.bckg_radiation = res_atu.map((el: any) => el.data.bckg_radiation)
        data.ds18b20 = res_atu.map((el: any) => el.data.DS18B20_temp)
        data.co2 = res_atu.map((el: any) => el.data.CO2)
        data.voc = res_atu.map((el: any) => el.data.TVOC)
        data.time_stamp_caro = res_caro.map((el: any) => el.data.time_stamp)
        data.time_stamp_atu = res_atu.map((el: any) => el.data.time_stamp)
        data.gps_speed = res_caro.map((el: any) => el.data.speed)
        data.gps_msl = res_caro.map((el: any) => el.data.MASL)
        data.gps_online_sat = res_caro.map((el: any) => el.data.sat_num)

        console.log(res_atu)
        return data
    }catch (e){
        console.error("Error in request: ", e)
        throw e
    }

}

export async function GetSysControlData(): Promise<any>{
    const req = await fetch("https://rovercon.herokuapp.com/get_sys_control")
    return req.json()
}