import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {TerminalContextProvider} from "react-terminal";
import {CookiesProvider} from 'react-cookie';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const theme = createTheme({
    palette: {
        mode: 'dark',
    }
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <CookiesProvider>
        <TerminalContextProvider>
            <ThemeProvider theme={theme}>
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <App/>
                </DevSupport>
            </ThemeProvider>
        </TerminalContextProvider>
    </CookiesProvider>
);

