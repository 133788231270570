
interface NotificationProps{
    is_notify_open: boolean
}
export default function Notification(props: NotificationProps){
    return(props.is_notify_open) ? (
        <div className={'fixed top-0 left-0 w-screen h-screen flex justify-center items-center'}>
        <div className={'flex justify-center items-center rounded-2xl w-1/2 h-52 bg-color_primary2'}>
            <p className={ 'text-2xl text-font_color_1'}>Nothing here</p>
        </div>
        </div>
    ) : <></>
}