import React, {useEffect, useState} from 'react'
import Header from '../components/dashboard/Header'
import Sidebar from "../components/dashboard/Sidebar";
import MainContent from "../components/dashboard/MainContent";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MapContainer, TileLayer, LayersControl, LayerGroup, Marker, Popup} from 'react-leaflet'
import {LineChart} from '@mui/x-charts/LineChart';
import {GetDataMcuAtu,GetDataMcuCaro} from "../services/getDatamcu";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from "@mui/material/Chip";
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import WavesIcon from '@mui/icons-material/Waves';
import Tooltip from '@mui/material/Tooltip';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import {S1TempWatcher,S2TempWatcher,S3TempWatcher} from "../components/RoverSystemWatcher";
import Co2Icon from '@mui/icons-material/Co2';
import TVOCIcon from "../components/icons/TVOCIcon";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import {InfoMES1,InfoMES2,InfoMES3,InfoMES4, InfoSystem} from "../components/roversystemsiformator/Informator";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {SetPop} from "../components/feedback/AlertPop";
import {GpsWatcher} from "../components/RoverSystemWatcher";

//load icon for leaflet marker
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

interface IPlot {
    dht_t: number[],
    dht_h: number[],
    bmp_t: number[],
    bmp_p: number[],
    bmp_a: number[],
    bckg_rad: number[],
    atu_id: number[],
    caro_id: number[],
    co2: number[],
    voc: number[],
    time_stamp: string[],
    s1: number[],
    s2: number[],
    s3: number[],
    voltage: number[],
    gps_speed: number[],
    gps_msl: number[],
    online_sat: number[]
}

export default function Dashboard() {
    const [loading,Set_loading] = useState<boolean>(false)
    const [DataAtu, SetDataAtu] = useState<any>()
    const [DataCaro, SetDataCaro] = useState<any>()
    const [PlotData, SetPlotData] = useState<IPlot>({
        dht_t: [0],
        dht_h: [0],
        bmp_a: [0],
        bmp_t: [0],
        bmp_p: [0],
        atu_id: [0],
        caro_id: [0],
        bckg_rad: [0],
        time_stamp: ['0'],
        co2: [0],
        voc: [0],
        s1: [0],
        s2: [0],
        s3: [0],
        voltage: [0],
        gps_speed: [0],
        gps_msl: [0],
        online_sat: [0]
    })
    const [ServerDate,SetServerDate] = useState<string[]>([])
    const [MES1state, SetMS1state] = useState<boolean>(false)
    const [MES2state, SetMS2state] = useState<boolean>(false)
    const [MES3state, SetMS3state] = useState<boolean>(false)
    const [MES4state, SetMS4state] = useState<boolean>(false)
    const [Sysstate, SetSysstate] = useState<boolean>(false)
    const [Unavstate, SetUnavstate] = useState<boolean>(false)

    const fetchData = async (): Promise<void> => {
        const caro = await GetDataMcuCaro(10)
        const atu = await GetDataMcuAtu(10)

        let dataatu =  atu.map((el: any) => el.data)
        let datacaro = caro.map((el: any) => el.data)

        SetDataAtu(dataatu[0 as keyof unknown])
        SetDataCaro(datacaro[0 as keyof unknown])

        let date_hour: string[] = []
        let date_day: string[] = []

        let time = atu.map((el: any) => el.data.time_stamp)
        time.forEach((el: any) =>{
            date_hour.push(el.split(/(\s+)/)[2])
            date_day.push(el.split(/(\s+)/)[0])
        })

        SetPlotData({
            dht_t: atu.map((el: any) => el.data.DHT_temp),
            dht_h: atu.map((el: any) => el.data.DHT_hum),
            bmp_a: atu.map((el: any) => el.data.BMP_alt),
            bmp_p: atu.map((el: any) => el.data.BMP_press),
            bmp_t: atu.map((el: any) => el.data.BMP_temp),
            bckg_rad: atu.map((el: any) => convert_to_cpm(el.data.bckg_radiation)),
            caro_id: caro.map((el: any) => el.id),
            atu_id: atu.map((el: any) => el.id),
            co2: atu.map((el: any) => el.data.CO2),
            voc: atu.map((el: any) => el.data.TVOC),
            time_stamp: date_hour,
            voltage: caro.map((el: any) => el.data.mrb_voltage),
            s1: atu.map((el: any) => el.data.MRB_temp),
            s2: atu.map((el: any) => el.data.A4988_temp),
            s3: atu.map((el: any) => el.data.L298_temp),
            gps_speed: caro.map((el: any) => el.data.speed),
            gps_msl: caro.map((el: any) => el.data.MASL),
            online_sat: caro.map((el: any) => el.data.sat_num)
        })
        SetServerDate(date_day)
    }

    const convert_to_cpm = (val: number)=>{
        return Math.round(val / 0.00812)
    }

    const CheckOnlineSat = ()=>{
        if(DataCaro.lat == 0){
            SetPop(true,'Rover position is unknown.','error')
        }
    }

    useEffect(()=>{
        fetchData().then(()=>{
            Set_loading(true)
        })

        let interval = setInterval(()=>
            fetchData().then(()=>{
                Set_loading(true)
            }),1500)

        return ()=>{
            clearInterval(interval)
        }
    },[])

    const render = ()=>{
        return(loading) ? (
                <>
                    <div className={'h-[calc(100vh-64px)] w-[calc(100vw-64px)] lg:w-[calc(100vw-160px)] flex flex-col justify-start items-start overflow-auto'}>
                        <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>GENERAL</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={'w-full h-fit flex flex-wrap justify-center xl:justify-start gap-4'}>
                                    <div
                                        className={'w-60 h-60 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center overflow-hidden'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 fixed top-0'}>MES1</p>
                                        <div className={'w-10 h-10 bg-green-900 fixed top-0 right-0 rounded-bl-xl'}>
                                            <IconButton onClick={() => {
                                                SetMS1state(true)
                                            }}>
                                                <InfoIcon></InfoIcon>
                                            </IconButton>
                                        </div>
                                        <div className={'flex flex-col justify-center items-center gap-2'}>
                                            <Tooltip
                                                title='Ambient temperature measured by DHT-11 sensor in MEDA section. Temperature accuracy ±2°C.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`DHT ${DataAtu.DHT_temp.toFixed(2)}°C`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Air humidity measured by DHT-11 sensor in MEDA section. Humidity accuracy ±5RH.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<WaterDropIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`DHT ${DataAtu.DHT_hum}%RH`}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        className={'w-60 h-60 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center overflow-hidden'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 fixed top-0'}>MES2</p>
                                        <div className={'w-10 h-10 bg-green-900 fixed top-0 right-0 rounded-bl-xl'}>
                                            <IconButton onClick={() => {
                                                SetMS2state(true)
                                            }}>
                                                <InfoIcon></InfoIcon>
                                            </IconButton>
                                        </div>
                                        <div className={'flex flex-col justify-center items-center gap-2'}>
                                            <Tooltip
                                                title='Ambient temperature measured by BMP-180 sensor in MEDA section. Temperature accuracy ±0.1°C.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`BMP ${DataAtu.BMP_temp.toFixed(2)}°C`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Air pressure measured by BMP-180 sensor in MEDA section. Pressuer accuracy ±0.12°hPa.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<CompareArrowsIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`BMP ${DataAtu.BMP_press / 100}hPa`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Height above mean sea level measured by BMP-180 sensor in MEDA section. Height accuracy 1.0m.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<WavesIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`BMP ${DataAtu.BMP_alt.toFixed(2)}m.s.l`}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        className={'w-60 h-60 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-start items-center overflow-hidden'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300'}>MES3</p>
                                        <div className={'w-10 h-10 bg-green-900 fixed top-0 right-0 rounded-bl-xl'}>
                                            <IconButton onClick={() => {
                                                SetMS3state(true)
                                            }}>
                                                <InfoIcon></InfoIcon>
                                            </IconButton>
                                        </div>
                                        <div className={'flex flex-col justify-center items-center mt-3'}>
                                            <Tooltip
                                                title='Ambient temperature measured by DS18B20 sensor in MEDA section. Temperature accuracy ±0.5°C.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`DHT ${DataAtu.DS18B20_temp.toFixed(2)}°C`}/>
                                            </Tooltip>
                                        </div>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 mt-3'}>E.R.D</p>
                                        <div className={'flex flex-col justify-center items-center mt-3'}>
                                            <Tooltip
                                                title='Background radiation level measured by Geiger-Muller counter with J-305 tube. Result is realative only - measurement accuracy too low.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`DHT ${DataAtu.bckg_radiation.toFixed(2)}uSv/h`}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        className={'w-60 h-60 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center overflow-hidden'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 fixed top-0'}>System</p>
                                        <div className={'w-10 h-10 bg-green-900 fixed top-0 right-0 rounded-bl-xl'}>
                                            <IconButton onClick={() => {
                                                SetSysstate(true)
                                            }}>
                                                <InfoIcon></InfoIcon>
                                            </IconButton>
                                        </div>
                                        <div className={'flex flex-col justify-center items-center gap-2'}>
                                            <Tooltip
                                                title='Voltage level of MRB - Main Rover Battery.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<ElectricMeterIcon/>}
                                                      variant="filled" color="success"
                                                      label={`MRB ${DataCaro.mrb_voltage.toFixed(2)}V`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Tempearature of MRB cells.'
                                                followCursor={true}>
                                                <Chip sx={{
                                                    bgcolor: `hsl(${S1TempWatcher(DataAtu.MRB_temp.toFixed(2))}, 60%, 50%)`,
                                                    color: 'black'
                                                }} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color={'secondary'}
                                                      label={`S1 ${DataAtu.MRB_temp.toFixed(2)}°C`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Tempearature of A4988 SuperCam motors drivers.'
                                                followCursor={true}>
                                                <Chip sx={{
                                                    bgcolor: `hsl(${S2TempWatcher(DataAtu.A4988_temp.toFixed(2))}, 60%, 50%)`,
                                                    color: 'black'
                                                }} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color="secondary"
                                                      label={`S2 ${DataAtu.A4988_temp.toFixed(2)}°C`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Tempearature of L298N H-BRIDGE DC motors driver.'
                                                followCursor={true}>
                                                <Chip sx={{
                                                    bgcolor: `hsl(${S3TempWatcher(DataAtu.L298_temp.toFixed(2))}, 60%, 50%)`,
                                                    color: 'black'
                                                }} icon={<DeviceThermostatIcon/>}
                                                      variant="filled" color="secondary"
                                                      label={`S3 ${DataAtu.L298_temp.toFixed(2)}°C`}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        className={'w-60 h-60 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center overflow-hidden'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 fixed top-0'}>A.Q.M.S</p>
                                        <div className={'w-10 h-10 bg-green-900 fixed top-0 right-0 rounded-bl-xl'}>
                                            <IconButton onClick={() => {
                                                SetMS4state(true)
                                            }}>
                                                <InfoIcon></InfoIcon>
                                            </IconButton>
                                        </div>
                                        <div className={'flex flex-col justify-center items-center gap-2'}>
                                            <Tooltip
                                                title='Quantity of TVOC in enviorment measured by SGP30 sensor. TVOC accuracy 13ppb.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<TVOCIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`SGP30 ${DataAtu.TVOC}ppb`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Quantity of CO2 in enviorment measured by SGP30 sensor. CO2 accuracy 11ppm.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<Co2Icon/>}
                                                      variant="filled" color="primary"
                                                      label={`SGP30 ${DataAtu.CO2}ppm`}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        className={'w-60 h-60 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center overflow-hidden'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 fixed top-0'}>U.L.NAV</p>
                                        <div className={'w-10 h-10 bg-green-900 fixed top-0 right-0 rounded-bl-xl'}>
                                            <IconButton onClick={() => {
                                            }}>
                                                <InfoIcon></InfoIcon>
                                            </IconButton>
                                        </div>
                                        <div className={'flex flex-col justify-center items-center gap-2'}>
                                            <Tooltip
                                                title='Quantity of TVOC in enviorment measured by SGP30 sensor. TVOC accuracy 13ppb.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<ArrowUpwardIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`FRONT ${DataCaro.distance_front}cm`}/>
                                            </Tooltip>
                                            <Tooltip
                                                title='Quantity of CO2 in enviorment measured by SGP30 sensor. CO2 accuracy 11ppm.'
                                                followCursor={true}>
                                                <Chip className={'hover:bg-violet-100'} icon={<ArrowDownwardIcon/>}
                                                      variant="filled" color="primary"
                                                      label={`REAR ${DataCaro.distance_rear}cm`}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>MEDA</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={'w-full h-fit flex flex-wrap justify-center xl:justify-start gap-4'}>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.dht_t,
                                                    label: "MES1 temperature",
                                                    color: "#c5301f",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.dht_h,
                                                    label: "MES1 humidity",
                                                    color: "#b3c846",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.bmp_t,
                                                    label: "MES2 temperature",
                                                    color: "#c5301f",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.bmp_p,
                                                    label: "MES2 pressure",
                                                    color: "#00b7ff",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.co2,
                                                    label: "AQMS CO2",
                                                    color: "#a975c7",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.voc,
                                                    label: "AQMS TVOC",
                                                    color: "#a975c7",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>SYSTEM</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={'w-full h-fit flex flex-wrap justify-center xl:justify-start gap-4'}>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.s1,
                                                    label: "S1 temp",
                                                    color: "#ff0000",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.s2,
                                                    label: "S2 temp",
                                                    color: "#ff0000",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.s3,
                                                    label: "S3 temp",
                                                    color: "#ff0000",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-72 h-72 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.voltage,
                                                    label: "MRB voltage",
                                                    color: "#124cac",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{width: '100%', display: ''}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>GPS</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={'w-full h-fit flex flex-wrap justify-center xl:justify-start gap-4'}>
                                    <MapContainer whenReady={() => {
                                        CheckOnlineSat()
                                    }} className={'w-96 h-96 rounded-2xl'} center={[DataCaro.lat, DataCaro.lng]}
                                                  zoom={13} scrollWheelZoom={true}>
                                        <LayersControl>
                                            <LayersControl.BaseLayer name="Open Street Map">
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                            </LayersControl.BaseLayer>

                                            <LayersControl.BaseLayer name="Mapbox Map Satellite">
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                                />
                                            </LayersControl.BaseLayer>

                                            <LayersControl.BaseLayer checked name="Google Map">
                                                <TileLayer
                                                    attribution="Google Maps"
                                                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                                />
                                            </LayersControl.BaseLayer>

                                            <LayersControl.BaseLayer name="Google Map Satellite">
                                                <LayerGroup>
                                                    <TileLayer
                                                        attribution="Google Maps Satellite"
                                                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                                                    />
                                                    <TileLayer
                                                        url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                                                </LayerGroup>
                                            </LayersControl.BaseLayer>
                                        </LayersControl>
                                        <Marker position={[DataCaro.lat, DataCaro.lng]}>
                                            <Popup>Rover position</Popup>
                                        </Marker>
                                    </MapContainer>
                                    <div
                                        className={'w-72 h-96 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <p className={'text-xl text-white font-mono border-b-2 border-amber-300 fixed top-0'}>GPS</p>
                                        <div className={'flex flex-col justify-center items-center gap-2'}>
                                            <Chip sx={{bgcolor: `${GpsWatcher(DataCaro.lat)}`}} icon={<MyLocationIcon/>}
                                                  variant="filled"
                                                  label={`LAT ${DataCaro.lat}`}/>
                                            <Chip sx={{bgcolor: `${GpsWatcher(DataCaro.lat)}`}} icon={<MyLocationIcon/>}
                                                  variant="filled"
                                                  label={`LNG ${DataCaro.lng}`}/>
                                            <Chip icon={<MyLocationIcon/>}
                                                  variant="filled" color="primary"
                                                  label={`Time ${DataCaro.time}`}/>
                                            <Chip icon={<MyLocationIcon/>}
                                                  variant="filled" color="primary"
                                                  label={`Date ${DataCaro.date}`}/>
                                            <Chip sx={{bgcolor: `#228c67`}} icon={<MyLocationIcon/>}
                                                  variant="filled"
                                                  label={`Speed ${DataCaro.speed}`}/>
                                            <Chip sx={{bgcolor: `rgba(149,86,217,0.51)`}} icon={<MyLocationIcon/>}
                                                  variant="filled"
                                                  label={`M.S.L ${DataCaro.MASL}`}/>
                                            <Chip sx={{bgcolor: `#d73dca`}} icon={<MyLocationIcon/>}
                                                  variant="filled"
                                                  label={`Online sat ${DataCaro.sat_num}`}/>
                                        </div>
                                    </div>
                                    <div
                                        className={'w-80 h-80 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.gps_speed,
                                                    label: "GPS speed",
                                                    color: "#228c67",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-80 h-80 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.gps_msl,
                                                    label: "GPS M.S.L",
                                                    color: "rgba(149,86,217,0.51)",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={'w-80 h-80 bg-color_bckg/50 rounded-3xl backdrop-blur-md flex flex-col justify-center items-center'}>
                                        <LineChart
                                            xAxis={[{
                                                scaleType: 'point',
                                                data: PlotData.time_stamp,
                                                label: `Date - ${ServerDate[0]}`
                                            }]}
                                            series={[
                                                {
                                                    data: PlotData.online_sat,
                                                    label: "Online satelites",
                                                    color: "#d73dca",
                                                    showMark: false
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>
            ) :
            <></>
    }

    return (
        <div className="static w-screen h-screen flex flex-col">
            <InfoMES1 is_window_open={MES1state} state={(): void => SetMS1state(false)}/>
            <InfoMES2 is_window_open={MES2state} state={(): void => {
                SetMS2state(false)
            }}/>
            <InfoMES3 is_window_open={MES3state} state={(): void => {
                SetMS3state(false)
            }}/>
            <InfoMES4 is_window_open={MES4state} state={(): void => {
                SetMS4state(false)
            }}/>
            <InfoSystem is_window_open={Sysstate} state={(): void => {
                SetSysstate(false)
            }}></InfoSystem>
            <Header></Header>
            <div className={'flex flex-row'}>
                <Sidebar></Sidebar>
                <MainContent loading={loading}>
                    {render()}
                </MainContent>
            </div>
        </div>
    )
}