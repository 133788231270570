import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/Sidebar";
import MainContent from "../components/dashboard/MainContent";
import React, {useEffect, useRef, useState} from "react";
import fan_icon from '../assets/dashboard_icons/icons8-fan-48.png'
import Slider from "@mui/material/Slider";
import {AddToStorage,GetFromStorage} from "../services/StorageHandle";
import {SetControlCommand} from "../services/ControlCommand";
import { Canvas, useFrame } from "@react-three/fiber";
import {useGLTF, Stage, PresentationControls, OrbitControls, } from "@react-three/drei";
import {Button} from '@mui/material'
import {Object3D} from "three";
import {GetDataComponentMonitoring} from "../services/getDatamcu";
import {ModelLoading} from "../components/Loader";

export default function Control(){
    const [FanSpeed1, SetFanSpeed1] = useState<number>(parseInt(GetFromStorage('FanSpeed1')))
    const [FanSpeed2, SetFanSpeed2] = useState<number>(parseInt(GetFromStorage('FanSpeed2')))
    const [SuperCamRotationZ, SetSuperCamRotationZ] = useState<number>(0)
    const [SuperCamRotationY, SetSuperCamRotationY] = useState<number>(0)
    const [GPSRotationX, SetGPSRotationX] = useState<number>(0)
    const [GPSRotationZ, SetGPSRotationZ] = useState<number>(0)
    const [ITR,SetITR] = useState<number>(1500)
    const [RotationSpeed, SetRotationSpeed] = useState<number>(0)
    const [CompMonitor, SetCompMonitor] = useState<any>()
    const [ModelLoaded, SetModelLoaded] = useState<boolean>(false)

    const rescale = (val: number, oMin: number, oMax: number, min: number, max: number)=>(val - oMin) * (max - min) / (oMax - oMin) + min;

    const AckermanSteering = (val: number): {ang1: number, ang2: number, ang3: number, ang4: number}=>{
        let r: number = 0;
        let d1: number = 196;
        let d2: number = 208;
        let d3: number = 222;
        let d4: number = 228;

        if (val > 1500) {
            r = rescale(val, 1500, 2000, 1000, 600);
        } else if (val < 1500) {
            r = rescale(val, 1500, 1000, 1000, 600);
        }

        let ang1: number = Math.round((Math.atan(d3 / (r + d1))) * 180 / Math.PI);
        let ang2: number = Math.round((Math.atan(d2 / (r + d1))) * 180 / Math.PI);
        let ang3: number = Math.round((Math.atan(d3 / (r - d1))) * 180 / Math.PI);
        let ang4: number = Math.round((Math.atan(d2 / (r - d1))) * 180 / Math.PI);

        if (val < 1480) {
            return {
                ang1: 0 - ang1,
                ang2: 0 + ang2,
                ang3: 0 - ang3,
                ang4: 0 + ang4
            }
        } else if (val > 1520) {
            return {
                ang1: 0 + ang1,
                ang2: 0 - ang2,
                ang3: 0 + ang3,
                ang4: 0 - ang4
            }
        } else {
            return {
                ang1: 0,
                ang2: 0,
                ang3: 0,
                ang4: 0
            }
        }
    }

    const ChangeRotation = (val: number)=>{
        if(val < -10){
            return -0.01
        }else if(val > 10){
            return 0.01
        }else{
            return 0
        }
    }

    const RoverSteeringFetch = async ()=>{
       const req = await fetch('https://rovercon.herokuapp.com/change_steering_data',{
            method: 'POST',
            mode: 'cors',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                input_turn_radius: ITR,
                speed_dir: RotationSpeed,
                sc_cam_pan: SuperCamRotationZ,
                sc_cam_tilt: SuperCamRotationY,
                gps_z: GPSRotationZ,
                gps_x: GPSRotationX
            })
        })

        return req.json()
    }

    RoverSteeringFetch().then((res)=>{
        console.log(res)
    }).catch((err)=>{
        console.error("Error: ",err)
        throw err
    })

    useEffect(() => {
        const UpdateGamepad = ()=>{
            if(navigator.getGamepads()[0]) {

                const pad = navigator.getGamepads()[0]!
                let axis_itr: number = Math.floor(pad.axes[0] * 500)
                let speed_dir: number = Math.floor(pad.axes[3] * 255)

                SetITR(ITR - axis_itr)
                SetRotationSpeed(speed_dir)
                requestAnimationFrame(UpdateGamepad)
            }
        }

        window.addEventListener("gamepadconnected",()=>{
            UpdateGamepad()
        })

        const interval = setInterval(()=>{
            GetDataComponentMonitoring().then((res)=>{
                SetCompMonitor(res)
            })
        },1000)

        return()=>{
            clearInterval(interval)
        }
    }, []);


    const CheckFanSpin = (fan: number): boolean=>{
        if(fan === 1){
            if(FanSpeed1 > 0){
                return true
            }else {
                return false
            }
        }else {
            if(FanSpeed2 > 0){
                return true
            }else {
                return false
            }
        }
    }

    const CompWatchWarning = (num: number): boolean=>{
        if (CompMonitor === undefined){
            return true
        }
        switch (num){
            case 1: {
                return CompMonitor.a4988_temp > 40
            }
            case 2: {
                return CompMonitor.mrb_temp > 30
            }
            case 3: {
                return CompMonitor.l298_temp > 30
            }
            case 4: {
                return CompMonitor.sat_count === 0
            }
            default: {
                return true
            }
        }
    }

    console.log(ModelLoaded)

    const FanSpeed1SubmitCHanges = (val: number)=>{
        SetFanSpeed1(val)
        AddToStorage('FanSpeed1', val.toString())
        SetControlCommand('setfanspeed1',val,'-','-')
    }

    const FanSpeed2SubmitCHanges = (val: number)=>{
        SetFanSpeed2(val)
        AddToStorage('FanSpeed2', val.toString())
        SetControlCommand('setfanspeed2',val,'','')
    }

    const RoverBody = (props: any)=>{
        const rover = useGLTF('/roverMainBody.glb',true)

        if(rover){
            SetModelLoaded(true)
        }

        useFrame((state, delta)=>{
            rover.nodes['MeshInstance_482'].rotation.x -= ChangeRotation(RotationSpeed)
            rover.nodes['MeshInstance_485'].rotation.x -= ChangeRotation(RotationSpeed)
        })

        return(
            <primitive object={rover.scene}></primitive>
        )
    }

    const SuperCam = (props: any)=>{
        const SuperCam = useGLTF('/SuperCam.glb',true)
        const ref = useRef<Object3D>(null!)

        useFrame((state,delta)=>{
            ref.current.position.set(16.525,365.698,250.454)
            ref.current.rotation.y = SuperCamRotationZ * Math.PI/180
            SuperCam.nodes['Top_file'].rotation.x = SuperCamRotationY * Math.PI/180
        })

        return(
            <primitive object={SuperCam.scene} ref={ref}></primitive>
        )
    }

    const MotorFL = () =>{
        const MotorFL = useGLTF('/MotorFL.glb',true)
        const ref = useRef<Object3D>(null!)

        useFrame((state,delta)=>{
            ref.current.position.set(311.118,-59.448,335.904)
            ref.current.rotation.y = AckermanSteering(ITR).ang3 * Math.PI/180
            MotorFL.nodes['wheel_file'].rotation.x -= ChangeRotation(RotationSpeed)
        })

        return(
            <primitive object={MotorFL.scene} ref={ref}></primitive>
        )
    }

    const MotorFR = () =>{
        const MotorFR = useGLTF('/MotorFR.glb',true)
        const ref = useRef<Object3D>(null!)

        useFrame((state,delta)=>{
            ref.current.position.set(-86.797,-54.575,335.904)
            ref.current.rotation.y = Math.PI
            ref.current.rotation.y = Math.PI - AckermanSteering(ITR).ang2 * Math.PI/180
            MotorFR.nodes['wheel_file'].rotation.x += ChangeRotation(RotationSpeed)
        })

        return(
            <primitive object={MotorFR.scene} ref={ref}></primitive>
        )
    }

    const MotorRL = () =>{
        const MotorRL = useGLTF('/MotorRL.glb',true)
        const ref = useRef<Object3D>(null!)

        useFrame((state,delta)=>{
            ref.current.position.set(311.118,-59.448,-91.238)
            ref.current.rotation.y = AckermanSteering(ITR).ang4 * Math.PI/180
            MotorRL.nodes['wheel_file'].rotation.x -= ChangeRotation(RotationSpeed)
        })

        return(
            <primitive object={MotorRL.scene} ref={ref}></primitive>
        )
    }

    const MotorRR = () =>{
        const MotorRR = useGLTF('/MotorRR.glb',true)
        const ref = useRef<Object3D>(null!)

        useFrame((state,delta)=>{
            ref.current.position.set(-86.797,-54.575,-91.238)
            ref.current.rotation.y = Math.PI
            ref.current.rotation.y = Math.PI - AckermanSteering(ITR).ang1 * Math.PI/180
            MotorRR.nodes['wheel_file'].rotation.x += ChangeRotation(RotationSpeed)
        })

        return(
            <primitive object={MotorRR.scene} ref={ref}></primitive>
        )
    }

    const GPS = ()=>{
        const {scene,nodes} = useGLTF('/GPS.glb',true)
        const ref = useRef<Object3D>(null!)

        useFrame((state,delta)=>{
            ref.current.position.set(179.619,238.451,13.267)
            ref.current.rotation.y = GPSRotationZ * Math.PI/180
            nodes['yaxis_file'].rotation.z = GPSRotationX * Math.PI/180
        })


        return(
            <primitive object={scene} ref={ref}></primitive>
        )
    }


    const ReturnToZero = ()=>{
        SetSuperCamRotationZ(0)
        SetSuperCamRotationY(0)
    }

    const ReturnToZeroGPS = ()=>{
        SetGPSRotationX(0)
        SetGPSRotationZ(0)
    }

    const RenderModel = ()=>{
            <Canvas dpr={[1, 2]} shadows camera={{fov: 45}}>
                <color attach="background" args={["#101010"]}/>
                <PresentationControls speed={1.5} global zoom={.5} polar={[-0.1, Math.PI / 4]}>
                    <Stage environment={'sunset'}>
                        <RoverBody></RoverBody>
                        <SuperCam></SuperCam>
                        <MotorFL></MotorFL>
                        <MotorFR></MotorFR>
                        <MotorRL></MotorRL>
                        <MotorRR></MotorRR>
                        <GPS></GPS>
                    </Stage>
                </PresentationControls>
                <OrbitControls></OrbitControls>
            </Canvas>
    }


    return (
        <div className="static w-screen h-screen flex flex-col">
            <Header></Header>
            <div className={'flex flex-row'}>
                <Sidebar></Sidebar>
                <MainContent loading={true}>
                    <div className={'h-[calc(100vh-64px)] w-[calc(100vw-64px)] lg:w-[calc(100vw-160px)] flex flex-wrap justify-center items-center overflow-auto gap-2'}>
                        <div
                            className={'w-64 md:w-fit h-fit flex flex-wrap justify-center items-center bg-color_primary2 rounded-2xl p-2 mt-4'}>
                            <div className={'w-64 h-fit flex justify-center items-center flex-col'}>
                                <p className={'text-font_color_1 font-sans border-b-2 border-color_primary1'}>MOTOR
                                    BRIDGE FAN</p>
                                <img src={fan_icon} alt="fan speed icon"
                                     className={`mt-2 ${(CheckFanSpin(1)) ? 'animate-spin' : 'animate-none'}`}/>
                                <Slider
                                    sx={{width: '80%', marginTop: '2px'}}
                                    aria-label="Range"
                                    defaultValue={parseInt(GetFromStorage('FanSpeed1'))}
                                    valueLabelDisplay="auto"
                                    step={10}
                                    min={0}
                                    max={255}
                                    value={FanSpeed1}
                                    onChangeCommitted={(e, val) => {
                                        FanSpeed1SubmitCHanges(val as number)
                                    }}
                                />
                                <p className={'text-white'}>Speed value: {FanSpeed1}</p>
                            </div>
                            <div className={'w-64 h-fit flex justify-center items-center flex-col'}>
                                <p className={'text-font_color_1 font-sans border-b-2 border-color_primary1 mt-2 md:mt-0'}>MOTHERBOARD
                                    FAN</p>
                                <img src={fan_icon} alt="fan speed icon"
                                     className={`mt-2 ${(CheckFanSpin(2)) ? 'animate-spin' : 'animate-none'}`}/>
                                <Slider
                                    sx={{width: '80%', marginTop: '2px'}}
                                    aria-label="Range"
                                    defaultValue={parseInt(GetFromStorage('FanSpeed2'))}
                                    valueLabelDisplay="auto"
                                    step={10}
                                    min={0}
                                    max={255}
                                    value={FanSpeed2}
                                    onChangeCommitted={(e, val) => {
                                        FanSpeed2SubmitCHanges(val as number)
                                    }}
                                />
                                <p className={'text-white'}>Speed value: {FanSpeed2}</p>
                            </div>
                            <div className={'w-full h-[300px] md:w-64 flex flex-col justify-center items-center'}>
                                <p className={'text-font_color_1 font-sans border-b-2 border-color_primary1 mb-2'}>SUPER
                                    CAM</p>
                                <Slider
                                    sx={{height: '80%'}}
                                    aria-label="Range"
                                    valueLabelDisplay="auto"
                                    defaultValue={0}
                                    step={1}
                                    min={-90}
                                    max={90}
                                    orientation={'vertical'}
                                    value={SuperCamRotationY}
                                    onChange={(e, val) => {
                                        SetSuperCamRotationY(val as number)
                                    }}
                                />
                                <Slider
                                    sx={{width: '80%'}}
                                    aria-label="Range"
                                    valueLabelDisplay="auto"
                                    defaultValue={0}
                                    step={1}
                                    min={-180}
                                    max={180}
                                    orientation={'horizontal'}
                                    value={SuperCamRotationZ}
                                    onChange={(e, val) => {
                                        SetSuperCamRotationZ(val as number)
                                    }}
                                />
                                <div className={'w-fit h-fit flex flex-col items-center justify-center gap-2'}>
                                    <Button variant={'outlined'} color={'primary'} onClick={() => {
                                        ReturnToZero()
                                    }}>AUTO HOME</Button>
                                    <Button variant={'outlined'} color={'primary'} onClick={() => {
                                        ReturnToZero()
                                    }}>RETURN TO HOME</Button>
                                </div>
                            </div>
                            <div className={'w-full h-[300px] md:w-64 flex flex-col justify-center items-center'}>
                                <p className={'text-font_color_1 font-sans border-b-2 border-color_primary1 mb-2'}>GPS</p>
                                <Slider
                                    sx={{height: '80%'}}
                                    aria-label="Range"
                                    valueLabelDisplay="auto"
                                    defaultValue={0}
                                    step={1}
                                    min={-70}
                                    max={70}
                                    orientation={'vertical'}
                                    value={GPSRotationX}
                                    onChange={(e, val) => {
                                        SetGPSRotationX(val as number)
                                    }}
                                />
                                <Slider
                                    sx={{width: '80%'}}
                                    aria-label="Range"
                                    valueLabelDisplay="auto"
                                    defaultValue={0}
                                    step={1}
                                    min={-90}
                                    max={90}
                                    orientation={'horizontal'}
                                    value={GPSRotationZ}
                                    onChange={(e, val) => {
                                        SetGPSRotationZ(val as number)
                                    }}
                                />
                                <div className={'w-fit h-fit flex flex-col items-center justify-center gap-2'}>
                                    <Button variant={'outlined'} color={'primary'} onClick={() => {
                                        ReturnToZeroGPS()
                                    }}>RETURN TO HOME</Button>
                                </div>
                            </div>
                        </div>
                        <div className={'w-9/12 h-[500px] bg-color_bckg'}>
                            <Canvas dpr={[1, 2]} shadows camera={{fov: 45}}>
                                <color attach="background" args={["#101010"]}/>
                                <PresentationControls speed={1.5} global zoom={.5} polar={[-0.1, Math.PI / 4]}>
                                    <Stage environment={'sunset'}>
                                        <RoverBody></RoverBody>
                                        <SuperCam></SuperCam>
                                        <MotorFL></MotorFL>
                                        <MotorFR></MotorFR>
                                        <MotorRL></MotorRL>
                                        <MotorRR></MotorRR>
                                        <GPS></GPS>
                                    </Stage>
                                </PresentationControls>
                                <OrbitControls></OrbitControls>
                            </Canvas>
                        </div>
                    </div>
                </MainContent>
            </div>
        </div>
    )
}