import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/Sidebar";
import MainContent from "../components/dashboard/MainContent";
import React, {useState} from "react";

export default function Mission(){
    const [loading,Set_loading] = useState<boolean>(false)

    return (
        <div className="static w-screen h-screen flex flex-col">
            <Header></Header>
            <div className={'flex flex-row'}>
                <Sidebar></Sidebar>
                <MainContent loading={loading}>
                    <p className={'text-amber-50'}>ssss</p>
                </MainContent>
            </div>
        </div>
    )
}