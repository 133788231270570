import React, {useEffect, useState} from 'react'
import {createTheme,Button} from "@mui/material/";
import {ThemeProvider} from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BarChartIcon from '@mui/icons-material/BarChart';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import Chip from "@mui/material/Chip";
import {S1TempWatcher,S2TempWatcher,S3TempWatcher,FanWatcher,SatWatcher} from "../RoverSystemWatcher";
import Tooltip from "@mui/material/Tooltip";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import {GetDataComponentMonitoring} from "../../services/getDatamcu";

const theme  = createTheme({
    palette: {
        primary: {
            main: '#ffda40'
        }
    }
})

export default function Sidebar(){

    const [Component, SetComponent] =  useState<any>({})
    const [loading,Set_loading] = useState<boolean>(false)

    const FetchData = async () => {
        const component_m = await GetDataComponentMonitoring()

        SetComponent(component_m)
    }

    useEffect(() => {
        FetchData().then(()=>{
            Set_loading(true)
        })

        const interval = setInterval(() => {
            FetchData().catch((err)=>{
                console.error("Error: ", err)
            })
        }, 4000)

        return (): void =>{
            clearInterval(interval)
        }
    }, []);

    const render = ()=>{
        return(loading) ? (
            <div className={'w-40 h-fit bg-color_bckg/80 backdrop-blur-md rounded-2xl flex flex-col justify-center items-center gap-1 p-3 fixed bottom-0'}>
                <Tooltip
                    title='Voltage level of MRB - Main Rover Battery.'
                    followCursor={true}>
                    <Chip icon={<ElectricMeterIcon/>}
                          variant="filled" color="success"
                          label={`MRB ${Component.mrb_voltage.toFixed(2)}V`}/>
                </Tooltip>
                <Tooltip
                    title='Tempearature of MRB cells.'
                    followCursor={true}>
                    <Chip sx={{
                        bgcolor: `hsl(${S1TempWatcher(Component.mrb_temp.toFixed(2))}, 60%, 50%)`,
                        color: 'black'
                    }} icon={<DeviceThermostatIcon/>}
                          variant="filled" color="secondary"
                          label={`S1 ${Component.mrb_temp.toFixed(2)}°C`}/>
                </Tooltip>
                <Tooltip
                    title='Tempearature of A4988 SuperCam motors drivers.'
                    followCursor={true}>
                    <Chip sx={{
                        bgcolor: `hsl(${S2TempWatcher(Component.a4988_temp.toFixed(2))}, 60%, 50%)`,
                        color: 'black'
                    }} icon={<DeviceThermostatIcon/>}
                          variant="filled" color="secondary"
                          label={`S2 ${Component.a4988_temp.toFixed(2)}°C`}/>
                </Tooltip>
                <Tooltip
                    title='Tempearature of L298N H-BRIDGE DC motors driver.'
                    followCursor={true}>
                    <Chip sx={{
                        bgcolor: `hsl(${S3TempWatcher(Component.l298_temp.toFixed(2))}, 60%, 50%)`,
                        color: 'black'
                    }} icon={<DeviceThermostatIcon/>}
                          variant="filled" color="secondary"
                          label={`S3 ${Component.l298_temp.toFixed(2)}°C`}/>
                </Tooltip>
                <Tooltip
                    title='Motherboard fan.'
                    followCursor={true}>
                    <Chip sx={{
                        bgcolor: `${FanWatcher(Component.fan1)}`,
                        color: 'black'
                    }} icon={<HeatPumpIcon/>}
                          variant="filled" color="secondary"
                          label={`FAN1 ${Component.fan1}%`}/>
                </Tooltip>
                <Tooltip
                    title='L298N fan.'
                    followCursor={true}>
                    <Chip sx={{
                        bgcolor: `${FanWatcher(Component.fan2)}`,
                        color: 'black'
                    }} icon={<HeatPumpIcon/>}
                          variant="filled" color="secondary"
                          label={`FAN2 ${Component.fan2}%`}/>
                </Tooltip>
                <Tooltip
                    title='Number of connected satelites.'
                    followCursor={true}>
                    <Chip sx={{
                        bgcolor: `${SatWatcher(parseInt(Component.sat_count))}`,
                        color: 'black'
                    }} icon={<SatelliteAltIcon/>}
                          variant="filled" color="secondary"
                          label={`SAT ${Component.sat_count}`}/>
                </Tooltip>
            </div>
        ) : <></>
    }


    return (
        <ThemeProvider theme={theme}>
            <div id='menu'
                 className={`w-40 h-[calc(100vh-64px)] bg-color_primary2 hidden sm:hidden flex-col md:hidden lg:flex`}>
                <Button href={'/dashboard'} startIcon={<DashboardIcon/>}>ADMIN</Button>
                <Button href={'/dashboard/charts'} startIcon={<BarChartIcon/>}>CHARTS</Button>
                <Button href={'/dashboard/control'} startIcon={<ControlCameraIcon/>}>Control</Button>
                <Button href={'/dashboard/system'} startIcon={<SettingsSuggestIcon/>}>SYSTEM</Button>
                <Button href={'/dashboard/account'} startIcon={<AccountBoxIcon/>}>ACCOUNT</Button>

                {render()}
        </div>
    <div id='menu'
         className={`w-16 h-[calc(100vh-64px)] bg-color_primary2 flex sm:flex flex-col md:flex lg:hidden`}>
            <IconButton href={'/dashboard/dashboard'} aria-label="delete" color={'primary'}>
                <DashboardIcon/>
            </IconButton>
            <IconButton href={'/dashboard/charts'} aria-label="delete" color={'primary'}>
                <BarChartIcon/>
            </IconButton>
            <IconButton href={'/dashboard/control'} aria-label="delete" color={'primary'}>
                <ControlCameraIcon/>
            </IconButton>
            <IconButton href={'/dashboard/system'} aria-label="delete" color={'primary'}>
                <SettingsSuggestIcon/>
            </IconButton>
            <IconButton href={'/dashboard/account'} aria-label="delete" color={'primary'}>
                <AccountBoxIcon/>
            </IconButton>
    </div>
            </ThemeProvider>
    )
}