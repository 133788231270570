import {ReactTerminal} from "react-terminal";
import {useState} from "react";
import {SetControlCommand} from "../services/ControlCommand";
import {SetPop} from "./feedback/AlertPop";

export default function Terminal(props: {trigger: boolean}){
    const [Error,SetError] = useState<boolean>(false)


    async function get_feedback() :Promise<any> {
            const req = await fetch('https://rovercon.herokuapp.com/get_sys_control', {
                mode: 'cors'
            })
            return req.json()
    }


    const welcome = (msg: string)=>{
        return(
            <span>
            <span style={{color:"green", fontWeight: "bold"}}>{msg}</span>
            <br />
            </span>
        )
    }

    const commands = {
        help: ()=>{
            return(
                <div className={'w-fit'}>
                    <hr/>
                    <p style={{color: 'green'}}>{`fanspeed1 -- setting first fan speed`}</p>
                    <span>Usage: fanspeed1 [speed] -- speed value must be from 0 to 255</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`fanspeed2 -- setting second fan speed`}</p>
                    <span>Usage: fanspeed2 [speed] -- speed value must be from 0 to 255</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`sys_check -- returning sys control values`}</p>
                    <span>Usage: sys_check</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`syspowercaro -- powering up or down MCU CARO`}</p>
                    <span>Usage: syspowercaro [state] -- state value must be "on" or "off"</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`syspoweratu -- powering up or down MCU ATU`}</p>
                    <span>Usage: syspoweratu [state] -- state value must be "on" or "off"</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`syspower -- powering up or down all rover systems`}</p>
                    <span>Usage: syspower [state] -- state value must be "on" or "off"</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`lidar -- turning on lidar sensor mounted on SC and making measurement`}</p>
                    <span>Usage: lidar</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`checkmotors -- checking servo motors`}</p>
                    <span>Usage: checkmotors</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`homesc -- homing Super Cam`}</p>
                    <span>Usage: homesc</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`drivetestlidar -- turn on lidar sensor mounted on SC and make measurement, going forward and backward by time interval`}</p>
                    <span>Usage: drivetestlidar [interval] -- interval in ms ex. drivetestlidar 5000</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`drivetestdelay -- going forward and backward by time interval`}</p>
                    <span>Usage: drivetestdelay [interval] -- interval in ms ex. drivetestlidar 5000</span>
                    <hr/>
                    <p style={{color: 'green'}}>{`check_gps -- checking gps motors`}</p>
                    <span>Usage: check_gps</span>
                </div>
            )
        },
        fanspeed1: (speed: number) => {
            if (speed < 0 || speed > 255) {
                return (
                    <span style={{color: 'red'}}>Must be from 0 to 255</span>
                )
            } else {
                SetControlCommand('setfanspeed1',speed,'-','-')

                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>{"Fan speed 1 => "}{speed}</span>
                )
            }
        },
        fanspeed2: (speed: number)=>{
            if(speed <0 || speed >255){
                return(
                    <span style={{color: 'red'}}>Must be from 0 to 255</span>
                )
            }else{
                SetControlCommand('setfanspeed2',speed,'-','-')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>{"Fan speed 2 => "}{speed}</span>
                )
            }
        },
        sys_check: async () => {
            const response = await get_feedback().catch((err)=>{
                console.error("Error: ",err)
                throw err
            })

            if(response.status === 'off'){
                SetPop(true,"Rover MCU's are off",'warning')
            }

            return (
                <div className={'w-fit'}>
                    <hr/>
                    <span style={{color: 'orange'}}>Rover status: {response.status}</span>
                    <hr/>
                    <span style={{color: 'orange'}}>L298 fan status: {response.arg}</span>
                    <hr/>
                    <span style={{color: 'orange'}}>Current MCU: {response.mcu}</span>
                    <hr/>
                    <span style={{color: 'orange'}}>Command: {response.command}</span>
                    <hr/>
                </div>
            )
        },
        syspowercaro: (state: string) =>{
            if(state.toUpperCase() === "ON"){
                SetControlCommand('-','-','on','caro')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>Mcu CARO ON</span>
                )
            }else if(state.toUpperCase() === "OFF") {
                SetControlCommand('-','-','off','caro')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>Mcu CARO OFF</span>
                )
            }else {
                return (
                    <span style={{color: "green"}}>Cannot recognize state</span>
                )
            }
        },
        syspoweratu: (state: string) =>{
            if(state.toUpperCase() === "ON"){
                SetControlCommand('-','-','on','atu')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>Mcu ATU ON</span>
                )
            }else if(state.toUpperCase() === "OFF") {
                SetControlCommand('-','-','off','atu')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>Mcu ATU OFF</span>
                )
            }else {
                return (
                    <span style={{color: "green"}}>Cannot recognize state</span>
                )
            }
        },
        syspower: (state: string) =>{
            if(state.toUpperCase() === "ON"){
                SetControlCommand('-','-','on','both')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>Power ON</span>
                )
            }else if(state.toUpperCase() === "OFF") {
                SetControlCommand('-','-','off','both')
                if(Error){
                    return
                }
                return(
                    <span style={{color: "green"}}>Power OFF</span>
                )
            }else {
                return (
                    <span style={{color: "green"}}>Cannot recognize state</span>
                )
            }
        },
        lidar: ()=>{
            SetControlCommand('lidar','-','-','-')

            return(
                <span style={{color: 'red'}}>Making measurement</span>
            )
        },
        checkmotors: ()=>{
            SetControlCommand('checkmotors','-','-','-')

            return(
                <span style={{color: 'green'}}>Checking motors</span>
            )
        },
        homesc: ()=>{
            SetControlCommand('homesc','-','-','-')

            return(
                <span style={{color: 'green'}}>Homing SuperCam</span>
            )
        },
        drivetestlidar: (interval: number)=>{
            SetControlCommand('drivetestlidar',interval,'-','-')

            return(
                <span style={{color: 'green'}}>Starting test</span>
            )
        },
        drivetestdelay: (interval: number)=>{
            SetControlCommand('drivetestdelay',interval,'-','-')

            return(
                <span style={{color: 'green'}}>Starting test</span>
            )
        },
        check_gps: ()=>{
            SetControlCommand('drivetestdelay','-','-','-')

            return(
                <span style={{color: 'green'}}>Checking gps</span>
            )
        }

    }


    return(props.trigger) ? (
        <div className={'fixed bottom-0 left-0 w-full h-1/2 hover:resize-y z-20'}>
            <ReactTerminal
                commands={commands}
                welcomeMessage={welcome("Rovercon V1.3")}
                showControlBar={false}
                showControlButtons={false}
                themes={{
                    my_custom_theme: {
                        themeBGColor: "black",
                        themeToolbarColor: "black",
                        themeColor: "white",
                        themePromptColor: "white"
                    }
                }}
                prompt = "ESP32 >"
                theme="my_custom_theme"
            />
        </div>
    ) : <></>

}