import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/Sidebar";
import MainContent from "../components/dashboard/MainContent";
import React, {useState} from "react";
import {createTheme,ThemeProvider, TextField} from "@mui/material/";

export default function Account(){
    const [loading,Set_loading] = useState<boolean>(false)

    const theme = createTheme({
        unstable_sxConfig:{
            color: {
                themeKey: '#ffffff'
            }
        },
        palette:{
            mode: 'dark',
            text: {
                primary: '#ffffff'
            }
        }
    })

    return (
        <div className="static w-screen h-screen flex flex-col">
            <Header></Header>
            <div className={'flex flex-row'}>
                <Sidebar></Sidebar>
                <MainContent loading={true}>
                    <div className={'h-[calc(100vh-64px)] w-[calc(100vw-64px)] lg:w-[calc(100vw-160px)] flex flex-wrap justify-center items-start overflow-auto'}>
                        <div className={'w-6/12 h-fit flex flex-col justify-center items-center'}>
                            <p className={'text-white font-semibold text-2xl border-b-2 border-white w-full mb-2'}>Change password</p>
                            <ThemeProvider theme={theme}>
                                <TextField id="standard-basic" label="E-mail" variant="standard" color={'primary'}/>
                                <TextField id="standard-basic" label="Current password" variant="standard" color={'primary'} type={'password'}/>
                                <TextField id="standard-basic" label="New password" variant="standard" color={'primary'} type={'password'}/>
                                <TextField id="standard-basic" label="Repeat new password" variant="standard" color={'primary'} type={'password'}/>
                            </ThemeProvider>
                        </div>
                    </div>
                </MainContent>
            </div>
        </div>
)
}