import IconButton from '@mui/material/IconButton';
import {ThemeProvider} from "@mui/material/styles";
import {createTheme,Button} from "@mui/material/";
import React, {useEffect, useState} from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import TerminalIcon from '@mui/icons-material/Terminal';
import Terminal from "../terminal";
import Settings from "../Settings";
import Notification from "../Notification";
import {AlertPop} from "../feedback/AlertPop";
import DangerousIcon from '@mui/icons-material/Dangerous';

const theme  = createTheme({
    palette: {
        primary: {
            main: '#FFC107'
        },
        secondary: {
            main: '#ff3a00'
        }
    }
})

interface Props{
    isopen: boolean,
    content: string,
    type: string
}


export default function Header(){

    const [opennotify,Setopennotify] = useState<boolean>(false)
    const [terminal_open,Setterminalopen] = useState<boolean>(false)
    const [settings_open, Setsettings_open] = useState<boolean>(false)
    const [notify_exist,Setnotifyexist] = useState<boolean>(false)

    return (
        <>
            <div className={'w-screen h-16 bg-color_primary2 flex justify-between items-center'}>
                <p className={'font-serif text-2xl text-font_color_1 ml-3'}>ROVERCON</p>
                <AlertPop></AlertPop>
                <IconButton onClick={() => {
                    Setopennotify(!opennotify)
                }} sx={{ml: 'auto', mr: 1}}>
                    <NotificationsIcon color={(!notify_exist) ? 'success' : 'error'}/>
                </IconButton>
                <IconButton onClick={() => {
                    Setsettings_open(!settings_open)
                }} sx={{mr: 1}}>
                    <SettingsIcon color={'primary'}></SettingsIcon>
                </IconButton>
                <ThemeProvider theme={theme}>
                    <Button onClick={() => Setterminalopen(!terminal_open)} variant={'contained'}
                            color={(terminal_open) ? 'secondary' : 'success'} endIcon={<TerminalIcon/>}
                            sx={{mr: 1}}>{(terminal_open) ? 'CLOSE' : 'OPEN'}</Button>
                    <Button sx={{mr: 1}} color={'error'} variant={'contained'} endIcon={<DangerousIcon/>} onClick={()=>{alert("Not implemented yet!!")}}>EMERGENCY STOP</Button>
                </ThemeProvider>
            </div>
            <Terminal trigger={terminal_open}></Terminal>
            <Settings open={settings_open} Setsettings_open={Setsettings_open}></Settings>
            <Notification is_notify_open={opennotify}></Notification>
        </>
    )
}