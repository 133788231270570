import React, { useState } from 'react';
import {Button} from '@mui/material'
import {SetPop} from "../components/feedback/AlertPop";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const SerialMonitor = () => {
    const [port, setPort] = useState<any>(null);
    const [input, setInput] = useState<any>(null);
    const [reader, setReader] = useState<any>(null);
    const [output, setOutput] = useState<string>('');
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [DialogOpen, SetDialogOpen] = useState<boolean>(false)

    const connect = async () => {

        try {
            const port = await navigator.serial.requestPort().catch((err)=>{
                console.error('Error connecting port', err);
                SetPop(true, 'Failed to open serial port.', 'error')
                throw err
            })
            await port.open({baudRate: 115200})
            setPort(port)
            setIsConnected(true)

            let decoder = new TextDecoderStream();
            let inputDone = port.readable.pipeTo(decoder.writable);
            let inputStream = decoder.readable;
            let reader = inputStream.getReader();

            setInput(inputDone)
            setReader(reader)

            while (true) {
                const {value, done} = await reader.read();
                if (value) {
                    console.log(value)
                    setOutput(prevState => prevState + value)
                }
                if (done) {
                    reader.releaseLock();
                    break;
                }
            }
        }catch (err){
            console.error("Error", err)
            throw err
        }
    };

    const disconnect = async () => {
        if(reader){
            await reader.cancel()
            await input.catch(()=>{})
            setReader(null)
            setInput(null)
        }
        if (port) {
            await port.close();
            setIsConnected(false);
            setPort(null);
        }
        SetPop(true,'Port successfully closed', 'success')
    };

    return(
        <div>
            <div className={'w-full h-fit flex flex-col'}>
                <div className={'w-full h-fit flex bg-black rounded-t-2xl'}>
                    <Button sx={{ml: 2}} color={!isConnected ? 'success' : 'error'} onClick={isConnected ? disconnect : connect}>
                        {isConnected ? 'Disconnect' : 'Connect'}
                    </Button>
                </div>
                <div className="w-full h-96 bg-black overflow-auto flex flex-col-reverse">
                    {output}
                </div>
            </div>
        </div>
    )
};

export default SerialMonitor;