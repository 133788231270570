import CircularProgress from '@mui/material/CircularProgress';

interface Props{
    loading: boolean
}

export default function Loader({loading}: Props){
    return(loading) ? (
        <div className={'relative top-0 left-0 w-full h-full flex justify-center items-center overflow-hidden'}>
            <CircularProgress color={'secondary'}></CircularProgress>
        </div>
    ) : <></>
}

export function ModelLoading({loading}: Props){
    return(!loading) ? (
        <div className={'w-full h-full flex flex-col justify-center items-center overflow-hidden'}>
            <CircularProgress color={'success'}></CircularProgress>
            <p className={'text-white font-roboto text-2xl '}>Rover Model is loading...</p>
        </div>
    ) : <></>
}