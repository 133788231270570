import {Navigate} from 'react-router-dom';
import Cookies from "universal-cookie";

export default function PrivateRoute({ children }: any) {
    const cookies = new Cookies()

    if (cookies.get('auth-token') === undefined) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login"  />
    }

    // authorized so return child components
    return children;
}