import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import {createTheme,ThemeProvider} from "@mui/material/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Cookies from 'universal-cookie'
import Slider from "@mui/material/Slider";


const theme = createTheme({palette: {mode: "dark"}})

export default function Settings(props: {open: boolean, Setsettings_open: (state: boolean)=>void}){

    const cookies = new Cookies()

    function Set_cookie(name: string, value: any): void {
        cookies.set(name,value,{path: '/dashboard'})
    }

    function Check_cookies(): void{
        if(cookies.get('is_components_draggable') === undefined){
            Set_cookie('is_components_draggable',false)
        }
        if(cookies.get('box_shadow_apply') === undefined){
            Set_cookie('box_shadow_apply',false)
        }
        if(cookies.get('plot_range') === undefined){
            Set_cookie('plot_range',100);
        }
    }

    useEffect(()=>{
        Check_cookies()

    },[])

    return(props.open) ? (
        <div className={'fixed top-0 left-0 w-screen h-screen z-20 bg-amber-50 bg-color_primary_3_settings/80 flex justify-center items-center'}>
            <div className={'fixed top-2 right-2'}>
            <IconButton onClick={()=>{props.Setsettings_open(!props.open)}}>
                    <CancelIcon color={'error'}></CancelIcon>
            </IconButton>
            </div>
            <div className={'container bg-color_bckg w-96 h-1/2 rounded-3xl flex flex-col items-center justify-start'}>
                <ThemeProvider theme={theme}>
                <FormControlLabel sx={{color: '#FFF'}} control={<Switch color={'success'} defaultChecked={!!cookies.get('is_components_draggable')} onChange={()=>{Set_cookie('is_components_draggable',!cookies.get('is_components_draggable'))}}></Switch>} label={"Draggable components"}></FormControlLabel>
                    <FormControlLabel sx={{color: '#FFF'}} control={<Switch color={'success'} defaultChecked={!!cookies.get('box_shadow_apply')} onChange={()=>{Set_cookie('box_shadow_apply',!cookies.get('box_shadow_apply'))}}></Switch>} label={"Box shadow"}></FormControlLabel>
                    <p className={'font-serif text-font_color_1 mt-1'}>Plot range:</p>
                    <Slider
                        sx={{width: '80%'}}
                        aria-label="Range"
                        defaultValue={cookies.get('plot_range')}
                        valueLabelDisplay="auto"
                        step={10}
                        min={10}
                        max={3000}
                        onChangeCommitted={(e,val)=>{Set_cookie('plot_range',val);}}
                    />
                </ThemeProvider>
            </div>
        </div>
    ) : <></>
}