import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/Sidebar";
import MainContent from "../components/dashboard/MainContent";
import React, {useEffect, useState} from "react";
import {GetDataMcuAtuPlot} from "../services/getDatamcu";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'universal-cookie'
import {LineChart} from "@mui/x-charts/LineChart";
import {Accordion, AccordionDetails, createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {GetChartsData} from "../services/getDatamcu";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const theme  = createTheme({
    palette: {
        primary: {
            main: '#FFC107'
        },
        secondary: {
            main: '#ff3a00'
        },
        mode: 'dark'
    }
})

interface IPlot{
    dht_temp: number[],
    dht_hum: number[],
    bmp_alt: number[],
    bmp_press: number[],
    bmp_temp: number[],
    bckg_radiation: number[],
    ds18b20: number[],
    time_stamp_caro: string[],
    time_stamp_atu: string[],
    co2: number[],
    voc: number[],
    gps_speed: number[],
    gps_msl: number[],
    gps_online_sat: number[]
}


export default function Charts(){
    const [loading,Set_loading] = useState<boolean>(false)
    const [PlotData,SetPlotData] = useState<IPlot>({
        dht_temp: [0],
        dht_hum: [0],
        bmp_alt: [0],
        bmp_press: [0],
        bmp_temp: [0],
        bckg_radiation: [0],
        ds18b20: [0],
        time_stamp_caro: ['0'],
        time_stamp_atu: ['0'],
        co2: [0],
        voc: [0],
        gps_speed: [0],
        gps_msl: [0],
        gps_online_sat: [0]
    })
    const [ServerDateHour, SetServerDateHour] = useState<string[]>(['0'])
    const [ServerDateDay, SetServerDateDay] = useState<string[]>(['0'])
    const [ServerDateHourCaro, SetServerDateHourCaro] = useState<string[]>(['0'])
    const [ServerDateDayCaro, SetServerDateDayCaro] = useState<string[]>(['0'])

    const FetchData = async ()=>{
        const data = await GetChartsData()

        console.log(data)

        let date_hour: string[] = []
        let date_day: string[] = []
        let date_hourCaro: string[] = []
        let date_dayCaro: string[] = []

        data.time_stamp_atu.forEach((el: any)=>{
            date_hour.push(el.split(/(\s+)/)[2])
            date_day.push(el.split(/(\s+)/)[0])
        })
        data.time_stamp_caro.forEach((el: any)=>{
            date_hourCaro.push(el.split(/(\s+)/)[2])
            date_dayCaro.push(el.split(/(\s+)/)[0])
        })

        SetServerDateHour(date_hour)
        SetServerDateDay(date_day)
        SetServerDateHourCaro(date_hourCaro)
        SetServerDateDayCaro(date_dayCaro)
        SetPlotData(data)
    }

    const HandleRefresh = ()=>{
        FetchData().catch((err)=>{
            throw err
        })
    }

    useEffect(() => {
        FetchData().then(()=>{
            Set_loading(true)
        }).catch((err)=>{
            throw err
        })
    }, []);


    const render = ()=>{
        return(loading) ? (
                <>
                    <div className={'h-[calc(100vh-64px)] w-[calc(100vw-64px)] lg:w-[calc(100vw-160px)] flex flex-col justify-start items-start overflow-auto'}>
                        <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>MEDA</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ThemeProvider theme={theme}>
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.dht_temp,
                                                label: "Temperature DHT [°C]",
                                                color: "#59a14f",
                                                showMark: false
                                            },
                                            {
                                                curve: 'natural',
                                                data: PlotData.bmp_temp,
                                                label: "Temperature BMP [°C]",
                                                color: "#1aff00",
                                                showMark: false
                                            },
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.bmp_press,
                                                label: "Pressure BMP [Pa]",
                                                color: "#c19a1e",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.dht_hum,
                                                label: "Humidity DHT [%]",
                                                color: "#124cac",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.bmp_alt,
                                                label: "Altitude BMP [m.s.l]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.bckg_radiation,
                                                label: "Background radiation ERD [uSv/h]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                </ThemeProvider>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>AQMS</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ThemeProvider theme={theme}>
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.co2,
                                                label: "Co2 level AQMS [ppm]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHour, label: `Date - ${ServerDateDay[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.voc,
                                                label: "TVOC level AQMS [ppb]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                </ThemeProvider>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{width: '100%'}} defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className={'text-white font-serif'}>GPS</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ThemeProvider theme={theme}>
                                    <LineChart
                                        xAxis={[{data: ServerDateHourCaro, label: `Date - ${ServerDateDayCaro[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.gps_speed,
                                                label: "Rover speed GPS [km/h]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHourCaro, label: `Date - ${ServerDateDayCaro[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.gps_msl,
                                                label: "Rover MSL GPS [m.s.l]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                    <LineChart
                                        xAxis={[{data: ServerDateHourCaro, label: `Date - ${ServerDateDayCaro[0]}`, scaleType: 'point'}]}
                                        series={[
                                            {
                                                curve: 'natural',
                                                data: PlotData.gps_online_sat,
                                                label: "Rover online sat GPS [km/h]",
                                                color: "#c5301f",
                                                showMark: false
                                            }
                                        ]}
                                        height={500}
                                    />
                                </ThemeProvider>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>
            ) :
            <></>
    }

    return (
        <div className="static w-screen h-screen flex flex-col">
            <Header></Header>
            <div className={'flex flex-row'}>
                <Sidebar></Sidebar>
                <MainContent loading={loading}>
                    <div className={'fixed top-20 right-20 z-20'}>
                        <IconButton onClick={()=>{HandleRefresh()}} color='secondary'>
                            <RefreshIcon></RefreshIcon>
                        </IconButton>
                    </div>
                    {render()}
                </MainContent>
            </div>
        </div>
    )
}